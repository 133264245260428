import React from 'react'
import { getColorCode } from '../../../utils/helper'

const Online = () => {
  const color = getColorCode('greenToGreen')

  return (
    <>
      <svg width='58' height='58' viewBox='0 0 58 58' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_2141_8525)'>
          <path
            d='M43.5 56.2359H14.5C12.8525 56.2337 11.2732 55.5783 10.1083 54.4134C8.94333 53.2484 8.28791 51.6691 8.28571 50.0216V32.7597H3.45238C1.54943 32.7597 0 31.2103 0 29.3073C0 28.3462 0.408762 27.4237 1.12133 26.7774L27.5693 1.56952C27.9552 1.20353 28.4668 0.999512 28.9986 0.999512C29.5305 0.999512 30.042 1.20353 30.4279 1.56952L37.2857 8.0959V5.83114C37.2857 5.28176 37.504 4.75489 37.8924 4.36642C38.2809 3.97795 38.8078 3.75971 39.3571 3.75971H47.6429C48.1922 3.75971 48.7191 3.97795 49.1076 4.36642C49.496 4.75489 49.7143 5.28176 49.7143 5.83114V19.9389L56.9146 26.8106C57.5912 27.4237 58 28.3462 58 29.3073C58 31.2103 56.4506 32.7597 54.5476 32.7597H49.7143V50.0216C49.7143 53.4464 46.9275 56.2359 43.5 56.2359Z'
            fill={color}
          />
          <path
            d='M29 0.999512C28.4673 0.999051 27.955 1.2038 27.5693 1.57123L1.12133 26.7791C0.768712 27.0996 0.486848 27.4902 0.293764 27.9258C0.10068 28.3614 0.00062657 28.8325 0 29.309C0 31.212 1.54943 32.7614 3.45238 32.7614H8.28571V50.0233C8.28571 53.4481 11.0725 56.2376 14.5 56.2376H29V0.999512Z'
            fill='white'
            fillOpacity='0.12'
          />
          <path
            d='M29 24.7134C22.3834 24.7134 17 30.0968 17 36.7134C17 43.33 22.3834 48.7134 29 48.7134C35.6166 48.7134 41 43.33 41 36.7134C41 30.0968 35.6166 24.7134 29 24.7134Z'
            fill='white'
            stroke='white'
            strokeWidth='1.4'
          />
          <path
            d='M28.0723 38.1402L33.2101 32.4228C33.4649 32.1632 33.8146 32.007 34.1877 31.9899C34.5633 31.9727 34.9295 32.098 35.2096 32.3368C35.4893 32.5753 35.661 32.9078 35.6922 33.2633C35.7232 33.6165 35.6137 33.9691 35.3837 34.2482L28.951 41.4053C28.8218 41.5488 28.6631 41.6656 28.4844 41.748C28.3057 41.8303 28.1111 41.8762 27.9127 41.8826C27.7143 41.8889 27.5169 41.8556 27.3328 41.7848C27.1487 41.714 26.9822 41.6074 26.8434 41.4723L23.1644 37.8907C23.0313 37.761 22.9262 37.6077 22.8548 37.4398C22.7833 37.2718 22.7467 37.0923 22.7467 36.9112C22.7467 36.7301 22.7833 36.5505 22.8548 36.3825C22.9262 36.2146 23.0313 36.0613 23.1644 35.9316C23.2976 35.802 23.4563 35.6986 23.6317 35.6279C23.8071 35.5571 23.9954 35.5206 24.1859 35.5206C24.3764 35.5206 24.5648 35.5571 24.7402 35.6279C24.9156 35.6986 25.0743 35.802 25.2074 35.9316L27.4958 38.1594L27.7941 38.4498L28.0723 38.1402Z'
            fill={color}
            stroke='white'
            strokeWidth='0.8'
          />
        </g>
        <defs>
          <clipPath id='clip0_2141_8525'>
            <rect width='58' height='58' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}

export default Online
