import React from "react";

interface OnlineOfflineImageProps {
  fillColor: string; // Add a type annotation for the fillColor prop
  width?: string;
  height?: string;
}

const OnlineOfflineImage: React.FC<OnlineOfflineImageProps> = ({ fillColor,width, height}) => {
  return (
    <svg
        width={width ? width : '14px'}
        height={height ? height : '14px'}
        viewBox='0 0 10 10'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        >
        <circle
            cx='5'
            cy='5'
            r='5'
            fill={fillColor}
        />
    </svg>
  );
};

export default OnlineOfflineImage;
