import { useMutation } from '@tanstack/react-query'
import xcpemSec from '../axiosInstances/xcpemSec'
import { notifyError } from '../components/Toaster'

interface AddNotesProps {
  gw_uid: string
  note: string
}
interface EditNotesProps {
  gw_uid: string
  note: string
  id: string
}

const host = `https://${process.env.REACT_APP_IOTCORE}`
const baseUrl = `${host}`

export const addGatewayNote = () => {
  const postRequest = async ({ note, gw_uid }: AddNotesProps) => {
    try {
      const response = await xcpemSec.post(`${baseUrl}/gateways_support_notes_list`, {
        note,
        gw_uid
      })
      return response.data
    } catch (error: any) {
      if (error?.response?.status === 401) {
        return error?.response?.status
      } else {
        notifyError(`Add Note: ${error?.response?.data?.message || error?.message}`)
        throw new Error(error?.response?.data?.message || error?.message)
      }
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate, isLoading, error } = useMutation(postRequest)

  return { mutate, isLoading, error }
}
export const updateGatewayNote = () => {
  const putRequest = async ({ note, gw_uid, id }: EditNotesProps) => {
    try {
      const response = await xcpemSec.patch(`${baseUrl}/gateways_support_notes_list?id=eq.${id}`, {
        note,
        gw_uid
      })
      return response.data
    } catch (error: any) {
      if (error?.response?.status === 401) {
        return error?.response?.status
      } else {
        notifyError(`Update Note: ${error?.response?.data?.message || error?.message}`)
        throw new Error(error?.response?.data?.message || error?.message)
      }
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate, isLoading, error } = useMutation(putRequest)

  return { mutate, isLoading, error }
}
export const deleteGatewayNote = () => {
  const deleteRequest = async (id: any) => {
    try {
      const response = await xcpemSec.delete(`${baseUrl}/gateways_support_notes_list?id=eq.${id}`)
      return response.data
    } catch (error: any) {
      if (error?.response?.status === 401) {
        return error?.response?.status
      } else {
        throw new Error('Failed to delete resource')
      }
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate, isLoading, error } = useMutation(deleteRequest)

  return { mutate, isLoading, error }
}
