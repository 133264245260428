import React from 'react'

const TopologyImage = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_59_308)">
      <path d="M16.0488 11.4466C17.6787 11.4466 19 10.113 19 8.46795C19 6.82284 17.6787 5.48929 16.0488 5.48929C14.419 5.48929 13.0977 6.82284 13.0977 8.46795C13.0977 8.909 13.1952 9.32632 13.3657 9.70284L12.18 10.2721C11.7728 9.5426 10.9993 9.04915 10.1105 9.04915C9.94552 9.04915 9.78444 9.06621 9.62894 9.09861L8.87992 6.45203C9.34702 6.26607 9.67867 5.8089 9.67867 5.27136C9.67867 4.56924 9.11471 4 8.41899 4C7.72335 4 7.15935 4.56924 7.15935 5.27136C7.15935 5.97347 7.72335 6.54272 8.41899 6.54272C8.45932 6.54272 8.49734 6.53449 8.53669 6.53077L9.29163 9.19847C8.38389 9.53559 7.73519 10.4134 7.73519 11.4466C7.73519 11.6815 7.7702 11.9081 7.83259 12.1227L6.6308 12.5793C6.48808 12.2963 6.19995 12.1004 5.86376 12.1004C5.3867 12.1004 5 12.4907 5 12.9722C5 13.4537 5.3867 13.844 5.86376 13.844C6.34078 13.844 6.72752 13.4537 6.72752 12.9722C6.72752 12.9555 6.72357 12.9399 6.72262 12.9234L7.95746 12.4542C8.33484 13.2741 9.15582 13.844 10.1105 13.844C10.5468 13.844 10.9542 13.7232 11.3056 13.5161L12.34 15.0667C11.5948 15.6794 11.1182 16.6123 11.1182 17.6581C11.1182 19.5038 12.6006 21 14.4293 21C16.2579 21 17.7404 19.5038 17.7404 17.6581C17.7404 15.8124 16.2579 14.3162 14.4293 14.3162C13.7632 14.3162 13.1443 14.5166 12.6249 14.8583L11.5959 13.3158C12.138 12.8765 12.4859 12.2029 12.4859 11.4466C12.4859 11.1457 12.4286 10.8589 12.3283 10.5935L13.5312 10.016C14.0494 10.8728 14.9816 11.4466 16.0488 11.4466Z" fill="#90969D"/>
      </g>
      <defs>
      <clipPath id="clip0_59_308">
      <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
      </defs>
    </svg>
  )
}
export default TopologyImage
