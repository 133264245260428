import React, { createContext, useContext, useState, ReactNode } from 'react'

interface SessionContexProps {
  openModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  reCall?: boolean
  setReCall?: React.Dispatch<React.SetStateAction<boolean>> | any
  serviceApi?: boolean
  setServiceApi?: React.Dispatch<React.SetStateAction<boolean>> | any
}

const SessionContex = createContext<SessionContexProps | undefined>(undefined)

export const SessionContexProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [reCall, setReCall] = useState<boolean>(false)
  const [serviceApi, setServiceApi] = useState<boolean>(localStorage.getItem('isPCverified') === 'true' ? true : false)

  return (
    <SessionContex.Provider value={{ openModal, setOpenModal, reCall, setReCall, serviceApi, setServiceApi }}>
      {children}
    </SessionContex.Provider>
  )
}

export const useSessionContex = (): SessionContexProps => {
  const context = useContext(SessionContex)
  if (!context) {
    throw new Error('SessionContex must be used within an SessionContexProvider')
  }
  return context
}
