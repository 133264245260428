import { GlobalSearchAction, GlobalSearchState } from "./types";

const initialState: GlobalSearchState = {
  data: [],
  loading: false,
  error: null,
  assignItem: [],
};

const globalSearchReducer = (
  state = initialState,
  action: GlobalSearchAction
): GlobalSearchState => {
  switch (action.type) {
    case "GET_GLOBAL_SEARCH_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_GLOBAL_SEARCH_SUCCESS":
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case "GET_GLOBAL_SEARCH_FAILURE":
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default globalSearchReducer;
