import React from 'react'
import { getColorCode } from '../../../utils/helper'

const NeedUpdate = () => {
  const color = getColorCode('darkGrayToGray')

  return (
    <>
      <svg width='58' height='58' viewBox='0 0 58 58' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_2141_8550)'>
          <path
            d='M43.5 56.2359H14.5C12.8525 56.2337 11.2732 55.5783 10.1083 54.4134C8.94333 53.2484 8.28791 51.6691 8.28571 50.0216V32.7597H3.45238C1.54943 32.7597 0 31.2103 0 29.3073C0 28.3462 0.408762 27.4237 1.12133 26.7774L27.5693 1.56952C27.9552 1.20353 28.4668 0.999512 28.9986 0.999512C29.5305 0.999512 30.042 1.20353 30.4279 1.56952L37.2857 8.0959V5.83114C37.2857 5.28176 37.504 4.75489 37.8924 4.36642C38.2809 3.97795 38.8078 3.75971 39.3571 3.75971H47.6429C48.1922 3.75971 48.7191 3.97795 49.1076 4.36642C49.496 4.75489 49.7143 5.28176 49.7143 5.83114V19.9389L56.9146 26.8106C57.5912 27.4237 58 28.3462 58 29.3073C58 31.2103 56.4506 32.7597 54.5476 32.7597H49.7143V50.0216C49.7143 53.4464 46.9275 56.2359 43.5 56.2359Z'
            fill={color}
          />
          <path
            d='M29 0.999512C28.4673 0.999051 27.955 1.2038 27.5693 1.57123L1.12133 26.7791C0.768712 27.0996 0.486848 27.4902 0.293764 27.9258C0.10068 28.3614 0.00062657 28.8325 0 29.309C0 31.212 1.54943 32.7614 3.45238 32.7614H8.28571V50.0233C8.28571 53.4481 11.0725 56.2376 14.5 56.2376H29V0.999512Z'
            fill='white'
            fillOpacity='0.12'
          />
          <path
            d='M29 24.7134C22.3834 24.7134 17 30.0968 17 36.7134C17 43.33 22.3834 48.7134 29 48.7134C35.6166 48.7134 41 43.33 41 36.7134C41 30.0968 35.6166 24.7134 29 24.7134Z'
            fill='white'
            stroke='white'
            strokeWidth='1.4'
          />
          <g clipPath='url(#clip1_2141_8550)'>
            <path
              d='M34.358 38.2662L33.6542 37.8598C33.7165 37.5567 33.7462 37.2767 33.7462 36.9995C33.7462 36.7223 33.7165 36.4423 33.6543 36.1392L34.3581 35.7328C34.4792 35.6629 34.5675 35.5477 34.6038 35.4127C34.6399 35.2776 34.621 35.1336 34.5511 35.0125L33.4964 33.1857C33.3507 32.9335 33.0283 32.8471 32.776 32.9927L32.0786 33.3954C31.6415 33.0245 31.1342 32.7347 30.5821 32.5406V31.7261C30.5821 31.4348 30.346 31.1987 30.0548 31.1987H27.9454C27.6542 31.1987 27.4181 31.4348 27.4181 31.7261V32.5406C26.8661 32.7347 26.3587 33.0246 25.9216 33.3954L25.2242 32.9927C24.9719 32.847 24.6494 32.9335 24.5038 33.1857L23.4491 35.0125C23.3792 35.1336 23.3603 35.2775 23.3965 35.4126C23.4327 35.5477 23.521 35.6629 23.6422 35.7328L24.3459 36.1392C24.2836 36.4423 24.254 36.7223 24.254 36.9995C24.254 37.2767 24.2836 37.5567 24.3459 37.8599L23.6421 38.2662C23.521 38.3361 23.4326 38.4513 23.3964 38.5864C23.3602 38.7215 23.3791 38.8654 23.4491 38.9865L24.5038 40.8133C24.6494 41.0656 24.9719 41.152 25.2241 41.0063L25.9215 40.6037C26.3586 40.9745 26.866 41.2643 27.418 41.4584V42.2729C27.418 42.5642 27.6541 42.8003 27.9453 42.8003H30.0547C30.346 42.8003 30.5821 42.5642 30.5821 42.2729V41.4584C31.1341 41.2643 31.6415 40.9745 32.0786 40.6037L32.776 41.0063C33.0282 41.152 33.3507 41.0655 33.4963 40.8133L34.551 38.9865C34.5856 38.9266 34.6081 38.8604 34.6172 38.7917C34.6262 38.723 34.6217 38.6533 34.6037 38.5864C34.5858 38.5195 34.5549 38.4568 34.5127 38.4018C34.4706 38.3469 34.418 38.3008 34.358 38.2662ZM29.0001 38.5815C28.1277 38.5815 27.418 37.8718 27.418 36.9995C27.418 36.1272 28.1277 35.4175 29.0001 35.4175C29.8724 35.4175 30.5821 36.1272 30.5821 36.9995C30.5821 37.8718 29.8724 38.5815 29.0001 38.5815Z'
              fill={color}
            />
            <path
              d='M36.2123 31.6123L35.4665 29.375C35.3744 29.0987 35.0757 28.9493 34.7994 29.0415C34.5232 29.1336 34.3738 29.4322 34.4659 29.7085L34.5293 29.8987C34.0537 29.5366 33.5447 29.2206 33.0093 28.9548C31.7494 28.3298 30.363 27.9995 29 27.9995C26.609 27.9995 24.3526 28.9392 22.6462 30.6457C20.9397 32.3521 20 34.6086 20 36.9995C20 38.1038 20.232 39.2351 20.6894 40.362C20.7726 40.5669 20.97 40.6911 21.1782 40.6911C21.2443 40.6911 21.3115 40.6786 21.3764 40.6522C21.6463 40.5427 21.7762 40.2351 21.6667 39.9652C21.2606 38.9649 21.0547 37.9671 21.0547 36.9995C21.0547 32.6184 24.6189 29.0542 29 29.0542C30.608 29.0542 32.2314 29.5891 33.5801 30.5169C33.3223 30.464 33.0597 30.6106 32.9744 30.8665C32.8823 31.1428 33.0316 31.4414 33.3079 31.5336L35.5452 32.2794C35.6382 32.3103 35.7378 32.3148 35.8331 32.2923C35.9284 32.2698 36.0156 32.2212 36.0848 32.1519C36.1541 32.0827 36.2027 31.9955 36.2252 31.9002C36.2477 31.8049 36.2432 31.7052 36.2123 31.6123ZM37.3106 33.6371C37.201 33.3672 36.8934 33.2373 36.6236 33.3468C36.3537 33.4564 36.2238 33.7639 36.3333 34.0338C36.7394 35.0341 36.9453 36.0319 36.9453 36.9995C36.9453 41.3806 33.3811 44.9448 29 44.9448C27.392 44.9448 25.7686 44.4099 24.4199 43.4821C24.6777 43.535 24.9403 43.3885 25.0256 43.1325C25.1177 42.8563 24.9684 42.5576 24.6921 42.4655L22.4547 41.7197C22.3618 41.6887 22.2621 41.6842 22.1668 41.7067C22.0715 41.7292 21.9843 41.7778 21.9151 41.847C21.8458 41.9163 21.7972 42.0034 21.7747 42.0988C21.7522 42.1941 21.7567 42.2938 21.7877 42.3867L22.5335 44.6241C22.6072 44.8451 22.8129 44.9848 23.0337 44.9848C23.089 44.9848 23.1452 44.976 23.2005 44.9576C23.4768 44.8655 23.6261 44.5669 23.534 44.2906L23.4707 44.1004C23.9463 44.4625 24.4552 44.7785 24.9906 45.0443C26.2506 45.6692 27.637 45.9995 29 45.9995C31.391 45.9995 33.6474 45.0598 35.3538 43.3533C37.0603 41.647 38 39.3905 38 36.9995C38 35.8953 37.768 34.764 37.3106 33.6371Z'
              fill={color}
            />
          </g>
        </g>
        <defs>
          <clipPath id='clip0_2141_8550'>
            <rect width='58' height='58' fill='white' />
          </clipPath>
          <clipPath id='clip1_2141_8550'>
            <rect width='18' height='18' fill='white' transform='translate(20 27.9995)' />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}

export default NeedUpdate
