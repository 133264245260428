import React from 'react'

const Devices = () => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.697 7.6418H17.2912V5.22558C17.2912 5.0206 17.1216 4.85442 16.9124 4.85442H16.0572V0.494884C16.0572 0.22156 15.8311 0 15.5522 0H0.50505C0.226111 0 0 0.22156 0 0.494884V9.95618C0 10.2295 0.226111 10.4511 0.50505 10.4511H5.73066C5.63015 11.0169 5.41404 11.9518 4.97909 12.8799C4.90727 13.0331 4.92005 13.2116 5.01293 13.3536C5.10581 13.4955 5.26601 13.5814 5.43808 13.5814H9.0001V14.8815C9.0001 15.0865 9.16965 15.2527 9.37889 15.2527H14.8322V15.7031C14.8322 15.867 14.9679 16 15.1352 16H19.697C19.8643 16 20 15.867 20 15.7031V7.93873C20 7.77473 19.8643 7.6418 19.697 7.6418ZM9.0001 5.22558V9.46134H1.0101V0.989768H15.0472V4.85437H9.37889C9.1697 4.85442 9.0001 5.02055 9.0001 5.22558ZM14.8322 14.5104H9.75768V14.0794H12.7669V14.1142C12.7669 14.3192 12.9365 14.4854 13.1457 14.4854C13.3549 14.4854 13.5245 14.3192 13.5245 14.1142V14.0794H14.8322V14.5104ZM14.8322 7.93873V13.337H9.75768V5.59674H16.5336V7.6418H15.1352C14.9678 7.6418 14.8322 7.77473 14.8322 7.93873ZM19.3939 15.4061H15.4382V15.0565H17.1131V15.086C17.1131 15.2501 17.2487 15.383 17.4161 15.383C17.5834 15.383 17.7191 15.2501 17.7191 15.086V15.0565H19.3939V15.4061ZM19.3939 14.4626H15.4382V9.17916H19.3939V14.4626ZM19.3939 8.5853H15.4382V8.23566H19.3939V8.5853Z" fill="black"/>
    </svg>

  )
}
export default Devices
