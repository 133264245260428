import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
// import HighchartsMore from 'highcharts/highcharts-more'
import moment from 'moment'
import dayjs from 'dayjs'
import { getColorCode } from '../../../../utils/helper'

interface Props {
  freeMemory: {
    y: number
    timestamp: string
  }[]
  dataFilter: any
  interval: any
}
const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()

const convertTimestampFormat = (timestamp: any) => {
  const formattedTimestamp = dayjs.utc(timestamp, 'YYYYMMDDTHH:mm:ss').tz(timeZone).toDate()
  return formattedTimestamp
}

const GatewayFreeMemoryStatisticsLineChart: React.FC<Props> = ({ freeMemory, dataFilter, interval }) => {
  // const [dateArray, setDateArray] = useState<any>([])
  // const [shortArray, setShortArray] = useState<any>([])
  // const [interval, setInterval] = useState<any>(1 * 3600 * 1000)

  // // const [chartArray, setChartArray] = useState<any>([])
  // const updateDateArray = () => {
  //   // Get the current date
  //   const currentDate = dayjs.tz(dayjs.utc(), timeZone).format('YYYYMMDDTHH:mm:ss')
  //   if (dataFilter === 'day') {
  //     const dateArray = []
  //     dateArray?.push({ timestamp: dayjs(currentDate).format('YYYYMMDDTHH:mm:ss'), timeline: 0 })
  //     setDateArray(dateArray)
  //     setInterval(10)
  //   }
  //   if (dataFilter === 'isoWeek') {
  //     // Get the date from 3 months ago
  //     const monthsAgo = dayjs.tz(dayjs.utc().subtract(168, 'hours'), timeZone).format('YYYYMMDDTHH:mm:ss')

  //     // Create an array of dates between currentDate and threeMonthsAgo
  //     const dateArray = []
  //     let currentDay = dayjs(currentDate)
  //     const endDate = dayjs(monthsAgo)

  //     while (currentDay.isAfter(endDate) || currentDay.isSame(endDate)) {
  //       dateArray?.push({ timestamp: currentDay.format('YYYYMMDDTHH:mm:ss'), timeline: 0 })
  //       currentDay = currentDay.subtract(1, 'day')
  //     }
  //     setDateArray(dateArray)
  //     setInterval(24 * 3600 * 1000)
  //   }
  //   if (dataFilter === 'isoMonth') {
  //     // Get the date from 3 months ago
  //     const monthsAgo = dayjs.tz(dayjs.utc().subtract(1, 'months'), timeZone).format('YYYYMMDDTHH:mm:ss')

  //     // Create an array of dates between currentDate and threeMonthsAgo
  //     const dateArray = []
  //     let currentDay = dayjs(currentDate)
  //     const endDate = dayjs(monthsAgo)

  //     while (currentDay.isAfter(endDate) || currentDay.isSame(endDate)) {
  //       dateArray?.push({ timestamp: currentDay.format('YYYYMMDDTHH:mm:ss'), timeline: 0 })
  //       currentDay = currentDay.subtract(1, 'day')
  //     }
  //     setDateArray(dateArray)
  //     setInterval(48 * 3600 * 1000)
  //   }
  //   if (dataFilter === 'isoYear') {
  //     // Get the date from 3 months ago
  //     const threeMonthsAgo = dayjs.tz(dayjs.utc().subtract(3, 'months'), timeZone).format('YYYYMMDDTHH:mm:ss')

  //     // Create an array of dates between currentDate and threeMonthsAgo
  //     const dateArray = []
  //     let currentDay = dayjs(currentDate)
  //     const endDate = dayjs(threeMonthsAgo)

  //     while (currentDay.isAfter(endDate) || currentDay.isSame(endDate)) {
  //       dateArray?.push({ timestamp: currentDay.format('YYYYMMDDTHH:mm:ss'), timeline: 0 })
  //       currentDay = currentDay.subtract(1, 'day')
  //     }
  //     setDateArray(dateArray)
  //     setInterval(168 * 3600 * 1000)
  //   }
  // }
  // useEffect(() => {
  //   updateDateArray()
  // }, [dataFilter])
  // useEffect(() => {
  //   if (dataFilter !== 'day') {
  //     const newArray: any = dateArray?.map((item: any) => {
  //       let obj: any = { timestamp: dayjs(item.timestamp).format('YYYY-MM-DDTHH:mm:ss.SSSSSS'), y: 0 }
  //       const filterArray = freeMemory?.filter(
  //         (obj: any) => dayjs(obj.timestamp).format('YYYY-MM-DD') === dayjs(item.timestamp).format('YYYY-MM-DD')
  //       )

  //       const timeDate = convertTimestampFormat(dayjs(item.timestamp).format('YYYY-MM-DDTHH:mm:ss.SSSSSS'))
  //       if (filterArray && filterArray.length !== 0) {
  //         const avgY = filterArray.reduce((sum: any, obj: any) => sum + obj.y, 0) / filterArray.length
  //         obj = {
  //           timestamp: timeDate,
  //           y: avgY
  //         }
  //       } else {
  //         obj = {
  //           timestamp: timeDate,
  //           y: 0
  //         }
  //       }
  //       return obj
  //     })
  //     const sortedTimeArray: any =
  //       newArray &&
  //       newArray?.sort((a: any, b: any) => {
  //         let dateA: any = new Date(a.timestamp)
  //         let dateB: any = new Date(b.timestamp)
  //         return dateA - dateB
  //       })
  //     setShortArray(sortedTimeArray)
  //   } else {
  //     const chunkSize = Math.ceil(freeMemory.length / 100) // Change 100 to your desired level of aggregation
  //     // Create an array to hold the aggregated data
  //     const aggregatedfreeMemory = []
  //     // Loop through the original data and calculate the average for each chunk
  //     for (let i = 0; i < freeMemory.length; i += chunkSize) {
  //       const chunk = freeMemory.slice(i, i + chunkSize)
  //       // Calculate the average temperature for the chunk
  //       const totalTemperature = chunk.reduce((sum, dataPoint) => sum + dataPoint.y, 0)
  //       const averageTemperature = totalTemperature / chunk.length
  //       const timestamp = convertTimestampFormat(chunk[0].timestamp)
  //       // const timestamp = chunk[0].timestamp
  //       aggregatedfreeMemory.push({ y: averageTemperature, timestamp })
  //     }

  //     setShortArray(aggregatedfreeMemory)
  //   }
  // }, [dateArray])
  // useEffect(() => {
  //   dateArray?.map((item: any) => {
  //     const isTimestampPresent =
  //       chartArray &&
  //       chartArray?.some(
  //         (obj: any) => dayjs(obj.timestamp).format('YYYY-MM-DD') === dayjs(item.timestamp).format('YYYY-MM-DD')
  //       )

  //     if (!isTimestampPresent) {
  //       chartArray?.push({
  //         timestamp: dayjs(item.timestamp).format('YYYY-MM-DDTHH:mm:ss.SSSSSS'),
  //         y: 0
  //       })
  //     }
  //     return null
  //   })
  //   const sortedTimeArray: any =
  //     chartArray &&
  //     chartArray?.sort((a: any, b: any) => {
  //       let dateA: any = new Date(a.timestamp)
  //       let dateB: any = new Date(b.timestamp)

  //       return dateA - dateB
  //     })
  //   setShortArray(sortedTimeArray)
  // }, [chartArray])
  const timestamps = freeMemory && freeMemory?.map((entry: any) => entry.timestamp)

  const options: any = {
    chart: {
      type: 'line',
      height: 300
    },
    title: {
      enabled: false,
      text: ''
    },
    // xAxis: {
    //   categories: [],
    //   labels: {
    //     enabled: false
    //   },
    //   lineWidth: 0.6,
    //   lineColor: 'gray'
    // },
    xAxis: {
      categories: timestamps, // Set timestamps as categories for the x-axis
      type: 'datetime', // Specify that the x-axis values are datetime

      labels: {
        formatter: function (this: any) {
          // You can customize the datetime format here
          return Highcharts.dateFormat(
            `${process.env.REACT_APP_DATE_FORMAT === 'MM-DD-YYYY' ? '%m-%d-%Y' : '%d-%m-%Y'}`,
            this.value
          )
        }
      },
      tickInterval: interval
    },
    yAxis: {
      title: {
        enabled: false,
        text: ''
      },
      labels: {
        format: '{value} MB'
      },
      lineWidth: 0.6,
      lineColor: 'gray',
      tickInterval: 160,
      reversedStacks: true,
      endOnTick: true,
      showLastLabel: true,
      gridLineWidth: 0
    },
    legend: {
      enabled: true,
      itemStyle: {
        fontSize: '12px', // Set the font size for legend items
        borderColor: 'transparent' // Make the border invisible
      },
      labelFormatter: function (this: any) {
        return `<span style="display:inline-block; width: 0; height: 0;"></span> ${this.name}`
      },
      symbol: 'circle', // Set symbol to “circle”
      symbolWidth: 5, // Set symbolWidth to 0
      symbolHeight: 10 // Set symbolHeight to 0
    },
    lineWidth: 0,
    gridLineWidth: 0,
    series: [
      {
        name: 'Free Memory (MB)',
        data: freeMemory,
        color: getColorCode('chartPurpleBlue'),
        pointPlacement: 'on',
        showlegend: false,
        lineWidth: 2
      }
    ],
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function (this: any) {
        // @ts-ignore
        const timestamp =
          // moment(this.point.timestamp).local().fromNow() +
          '(' +
          // moment(this.point.timestamp).format(`${process.env.REACT_APP_DATE_FORMAT} HH:mm`) +
          // dayjs.tz(this.point.timestamp).format(`${process.env.REACT_APP_DATE_FORMAT} HH:mm`) +
          Highcharts.dateFormat(
            `${process.env.REACT_APP_DATE_FORMAT === 'MM-DD-YYYY' ? '%m-%d-%Y , %I:%M %p' : '%d-%m-%Y , %I:%M %p'}`,
            this.point.timestamp
          ) +
          ')'
        return (
          '<div class="custom-tooltip">' +
          '<span><b>' +
          this.series.name +
          ': </b></span>' +
          '<span>' +
          parseFloat(this.y as unknown as string).toFixed(2) +
          'MB' +
          '</span><br/>' +
          '<span>When: ' +
          timestamp +
          '</span><br/>' +
          '</div>'
        )
      }
    },
    credits: {
      enabled: false
    }
  }

  return (
    <div className='statistic-wrapper flex flex-col gap-y-2 w-full'>
      <h4 className='title text-[18px] font-sf-medium'>Router Free Memory</h4>
      <HighchartsReact highcharts={Highcharts} options={options} height={200} />
    </div>
  )
}

export default GatewayFreeMemoryStatisticsLineChart
