import { useNavigate } from 'react-router-dom'

const SidebarCollapseLogo = ({ className }: { className: string }) => {
  const navigation = useNavigate()
  return (
    <div
      className={className}
      onClick={() => {
        navigation('/dashboard')
      }}
    >
      <svg width='40' height='28' viewBox='0 0 40 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M22.6624 15.6995C23.9738 15.6995 25.0369 14.6339 25.0369 13.3193C25.0369 12.0048 23.9738 10.9391 22.6624 10.9391C21.351 10.9391 20.2878 12.0048 20.2878 13.3193C20.2878 14.6339 21.351 15.6995 22.6624 15.6995Z'
          fill='#FF715A'
        />
        <path
          d='M25.2585 19.234C25.0502 19.2342 24.8478 19.1644 24.6838 19.0357C24.5197 18.907 24.4035 18.7269 24.3537 18.5241C24.3039 18.3213 24.3234 18.1077 24.409 17.9174C24.4947 17.727 24.6416 17.571 24.8262 17.4742C25.5833 17.0776 26.2175 16.4807 26.6601 15.7483C27.1027 15.0159 27.3366 14.176 27.3366 13.3197C27.3366 12.4634 27.1027 11.6235 26.6601 10.8911C26.2175 10.1587 25.5833 9.5618 24.8262 9.16519C24.6074 9.05046 24.443 8.85328 24.3692 8.61706C24.2954 8.38083 24.3183 8.1249 24.4327 7.90556C24.5472 7.68622 24.7439 7.52145 24.9796 7.44749C25.2152 7.37352 25.4705 7.39643 25.6894 7.51117C26.7477 8.06582 27.6342 8.90041 28.2529 9.92438C28.8715 10.9483 29.1986 12.1226 29.1986 13.3197C29.1986 14.5168 28.8715 15.691 28.2529 16.715C27.6342 17.7389 26.7477 18.5735 25.6894 19.1282C25.5563 19.1978 25.4085 19.2341 25.2585 19.234Z'
          fill='#FF715A'
        />
        <path
          d='M28.241 22.2234C28.043 22.2238 27.85 22.1608 27.6901 22.0437C27.5303 21.9266 27.4118 21.7614 27.352 21.5721C27.2922 21.3829 27.2942 21.1795 27.3577 20.9915C27.4211 20.8034 27.5428 20.6406 27.7049 20.5266C28.8596 19.7145 29.8023 18.6355 30.4531 17.381C31.1038 16.1265 31.4436 14.7333 31.4436 13.3193C31.4436 11.9053 31.1038 10.5121 30.4531 9.25761C29.8023 8.00309 28.8596 6.92413 27.7049 6.11203C27.5028 5.96973 27.3655 5.7528 27.323 5.50897C27.2805 5.26514 27.3364 5.01438 27.4783 4.81185C27.6203 4.60932 27.8367 4.47161 28.0799 4.42902C28.3232 4.38643 28.5734 4.44245 28.7754 4.58475C30.1746 5.56912 31.3168 6.87678 32.1053 8.39713C32.8938 9.91749 33.3055 11.6058 33.3055 13.3194C33.3055 15.0329 32.8938 16.7212 32.1053 18.2416C31.3168 19.7619 30.1746 21.0696 28.7754 22.054C28.6189 22.1643 28.4323 22.2235 28.241 22.2234Z'
          fill='#FF715A'
        />
        <path
          d='M15.6336 13.3727L23.3286 4.65443C23.5687 4.36073 23.7483 4.02213 23.8569 3.65829C23.9654 3.29444 24.0008 2.91261 23.9609 2.53495C23.921 2.1573 23.8067 1.79134 23.6246 1.45835C23.4424 1.12535 23.1961 0.831935 22.8999 0.595148C22.6037 0.358361 22.2635 0.182916 21.8991 0.0790003C21.5347 -0.0249156 21.1534 -0.0552318 20.7772 -0.010188C20.401 0.0348557 20.0375 0.154362 19.7078 0.341391C19.378 0.528421 19.0886 0.779248 18.8564 1.07931L11.9764 8.77417L5.09615 1.07931C4.86434 0.778073 4.57504 0.526094 4.24512 0.338051C3.91519 0.150008 3.55123 0.0296618 3.17444 -0.0159764C2.79765 -0.0616147 2.41556 -0.0316319 2.05045 0.072225C1.68533 0.176082 1.34449 0.351736 1.04777 0.588953C0.751057 0.82617 0.504404 1.12021 0.322185 1.45393C0.139966 1.78765 0.0258252 2.15439 -0.0135876 2.53277C-0.0530005 2.91115 -0.016897 3.29361 0.0926194 3.65787C0.202136 4.02213 0.382875 4.36089 0.624306 4.65443L8.31904 13.3727L0.624306 21.9841C0.163387 22.5796 -0.0450305 23.3332 0.0441592 24.0816C0.133349 24.8301 0.512971 25.5132 1.10087 25.9832C1.68876 26.4531 2.43764 26.6722 3.18542 26.5928C3.93321 26.5135 4.61974 26.1421 5.09645 25.5592L11.9767 17.971L18.857 25.5592C19.3337 26.1421 20.0203 26.5135 20.7681 26.5928C21.5158 26.6722 22.2647 26.4531 22.8526 25.9832C23.4405 25.5132 23.8201 24.8301 23.9093 24.0816C23.9985 23.3332 23.7901 22.5796 23.3292 21.9841L15.6336 13.3727Z'
          fill='#091554'
        />
      </svg>
    </div>
  )
}

export default SidebarCollapseLogo
