import SecurityOverview from '../../../components/cyber-security/security-overview'
import ParentalLayout from '../../../Layouts/parentalLayout/parental-layout'
import { useParams } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import { BreadcrumbContext } from '../../../contexts/breadcrumbData'
import SessionTimeOutModal from '../../../components/SessionTimeOutModal'

const CyberDashboard = () => {
  const { updateBreadcrumbData, updateFilters } = useContext(BreadcrumbContext)
  const { id: serialNumber } = useParams()

  const gwuid = localStorage.getItem('gwuid')
  const email = localStorage.getItem('email')
  const mac = localStorage.getItem('mac')

  // useEffect(() => {

  //     if(email !== '' && gwuid !== '' && gwuid !== undefined && email !== undefined){
  //         const newBreadcrumbData = [
  //             {label: 'Home', href: `/dashboard`},
  //             {label: 'Customers', href: `/dashboard/${gwuid}`},
  //             {label: email || '-', href: `/dashboard/${gwuid}`},
  //             {label: 'Cyber Security', href: `/dashboard/${gwuid}`}
  //         ]
  //         const filters: any = []
  //         updateFilters(filters)
  //         updateBreadcrumbData(newBreadcrumbData)
  //     }
  // }, [email,gwuid, updateBreadcrumbData, updateFilters])

  return (
    <ParentalLayout serialNumber={serialNumber as any} useSidebar='Cyber'>
      <div className=''>
        {email !== '' &&
          email !== undefined &&
          email !== null &&
          gwuid !== '' &&
          gwuid !== undefined &&
          gwuid !== null &&
          mac !== '' &&
          mac !== undefined &&
          mac !== null && <SecurityOverview email={email} mac={mac} gwuid={gwuid} />}
      </div>
    </ParentalLayout>
  )
}

export default CyberDashboard
