import React from "react";

interface TelemetryDataProps {
  width?: string;
  height?: string;
}

const TelemetryData: React.FC<TelemetryDataProps> = ({ width, height}) => {
  return (
    <svg width={width ? width : '21px'} height={height ? height : '21px'} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.74195 4.37109H10.816C12.1647 4.37109 13.2581 5.46444 13.2581 6.81314V18.171C13.2581 19.5197 12.1647 20.6131 10.816 20.6131H3.44204C2.09334 20.6131 1 19.5197 1 18.171V6.81314C1 5.46444 2.09334 4.37109 3.44204 4.37109H6.51613" stroke="black" strokeWidth="0.610511" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.90343 17.2419H3.14298C2.80582 17.2419 2.53247 16.9686 2.53247 16.6314V8.65885C2.53247 8.32169 2.80582 8.04834 3.14298 8.04834H11.1155C11.4527 8.04834 11.726 8.32169 11.726 8.65885V16.6314C11.726 16.9686 11.4527 17.2419 11.1155 17.2419H7.12925" stroke="black" strokeWidth="0.610511" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.98413 8.04834V17.2419" stroke="black" strokeWidth="0.610511" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.77401 4.37113C5.66286 4.23197 5.59644 4.05553 5.59644 3.86355V3.65288C5.59644 3.2033 5.96087 2.83887 6.41045 2.83887H9.68568C10.1353 2.83887 10.4997 3.2033 10.4997 3.65288V3.86355C10.4997 4.05549 10.4333 4.23193 10.3221 4.37113" stroke="black" strokeWidth="0.610511" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.5979 2.83882C9.75032 1.45979 10.9195 0.387207 12.3391 0.387207H12.6456C14.1688 0.387207 15.4037 1.62203 15.4037 3.14529V12.0324" stroke="black" strokeWidth="0.610511" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.403 13.8709C15.9107 13.8709 16.3223 13.4593 16.3223 12.9516C16.3223 12.4438 15.9107 12.0322 15.403 12.0322C14.8952 12.0322 14.4836 12.4438 14.4836 12.9516C14.4836 13.4593 14.8952 13.8709 15.403 13.8709Z" stroke="black" strokeWidth="0.610511" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.8544 11.4198C18.3621 11.4198 18.7738 11.0081 18.7738 10.5004C18.7738 9.99266 18.3621 9.58105 17.8544 9.58105C17.3467 9.58105 16.9351 9.99266 16.9351 10.5004C16.9351 11.0081 17.3467 11.4198 17.8544 11.4198Z" stroke="black" strokeWidth="0.610511" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.002 10.1577C16.055 9.61064 15.4238 8.6039 15.4038 7.43555" stroke="black" strokeWidth="0.610511" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M19.0802 7.74202C19.588 7.74202 19.9996 7.33041 19.9996 6.82267C19.9996 6.31493 19.588 5.90332 19.0802 5.90332C18.5725 5.90332 18.1609 6.31493 18.1609 6.82267C18.1609 7.33041 18.5725 7.74202 19.0802 7.74202Z" stroke="black" strokeWidth="0.610511" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.1658 6.73702C16.7403 6.45945 15.404 5.5018 15.4038 3.7583" stroke="black" strokeWidth="0.610511" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.04785 2.83882C8.04785 1.48482 9.14547 0.387207 10.4995 0.387207H12.3382" stroke="black" strokeWidth="0.610511" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.5166 2.83882C6.5166 1.48482 7.61422 0.387207 8.96821 0.387207H10.5005" stroke="black" strokeWidth="0.610511" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.5003 11.1876L10.4193 11.2938C10.1603 11.6333 9.62837 11.5862 9.43533 11.2066L8.7597 9.89658C8.55689 9.49792 7.98822 9.47134 7.74788 9.84933L7.28654 10.5567C7.00733 10.9958 6.34684 10.8609 6.24224 10.3512L6.20996 10.1939" stroke="black" strokeWidth="0.610511" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.5003 13.0856L10.3511 13.3144C10.1107 13.6924 9.54205 13.6658 9.33924 13.2672C9.24078 13.0736 9.03964 12.9517 8.81985 12.9517H8.222C8.03921 12.9517 7.86713 13.0362 7.75744 13.18L7.50445 13.5115C7.25971 13.8323 6.76365 13.8116 6.54749 13.4716L6.20996 12.9517" stroke="black" strokeWidth="0.610511" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.20996 15.71H10.5003" stroke="black" strokeWidth="0.610511" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.45996 5.99463V6.48304" stroke="black" strokeWidth="0.610511" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.42603 6.47998C6.59717 6.67636 6.84895 6.80066 7.1299 6.80066C7.41086 6.80066 7.66264 6.6764 7.83378 6.47998" stroke="black" strokeWidth="0.610511" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.32837 6.4693C9.32837 6.15672 9.58177 5.90332 9.89435 5.90332C10.2069 5.90332 10.4603 6.15672 10.4603 6.4693" stroke="black" strokeWidth="0.610511" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
};

export default TelemetryData;
