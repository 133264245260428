import { useEffect, useState } from 'react'
// import { Formik } from 'formik'
import { useFormik } from 'formik'
import { Box, Button, Typography, useTheme } from '@mui/material'
import { getColorCode } from '../../utils/helper'
import { useAddDevice } from '../../hook/useAllCustomers'
import { useSessionContex } from '../../SessionContex'
import Papa from 'papaparse'
import { Close } from '@mui/icons-material'
import CustomerModal from '../../components/Customer-table/CustomerModal'
import MessageModal from './MessageModal'
import * as Yup from 'yup'

const AddDevice = ({ handleClose, MSOData, setCallDeviceDetail, setSnackBarInfo, setReCall }: any) => {
  const [title, setTitle] = useState('Add MSO')
  const theme = useTheme()
  const addDevice = useAddDevice()
  const { openModal, setOpenModal } = useSessionContex()
  const [GWList, setGWList] = useState({})
  const [list, setList] = useState<any>([])
  const [openListModal, setOpenListModal] = useState(false)
  const [messageArray, setMessageArray] = useState<any>([])

  useEffect(() => {
    setTitle('Add Device')
  }, [])
  const validationSchema = Yup.object().shape({
    image: Yup.mixed().test('fileType', 'File must be a CSV.', (value: any) => {
      // Check if a file is selected and if its type is CSV
      return value && value.type === 'text/csv'
    })
  })
  const formik: any = useFormik({
    initialValues: {
      image: ''
    },
    validationSchema,
    onSubmit: (values: any, actions: any) => {
      actions.setSubmitting(true)
      const payload = {
        _white_label: MSOData && MSOData.length !== 0 ? MSOData[0]?.white_label : '',
        _gateway_list: GWList
      }

      addDevice.mutate(payload, {
        onSuccess: (data: any) => {
          if (list?.length !== data.length) {
            let filteredValues = list.filter((value: any) => !data.some((item: any) => item.gw_uid === value))

            setMessageArray(filteredValues)
            setOpenListModal(true)
          } else {
            formik.resetForm()
            setReCall(true)
            setCallDeviceDetail(true)
            handleClose()
          }
          if (data.length !== 0) {
            setSnackBarInfo({
              isOpen: true,
              message: 'Device Added Successfully.',
              type: 'Closed'
            })
          }
          setTimeout(() => {
            actions.setSubmitting(false)
          }, 200)
          if (data === 401) {
            !openModal && setOpenModal(true)
          }
        },
        onError: (error: any) => {
          actions.setSubmitting(false)
          setSnackBarInfo({
            isOpen: true,
            message: `${error ? error : `Failed to add Device.`}`,
            type: 'redToRed'
          })
        }
      })
    }
  })
  const handleFile = (e: any) => {
    // Event listener on reader when the file
    // loads, we parse it and set the data.

    const reader = new FileReader()
    reader.onload = async ({ target }: any) => {
      const csv = Papa.parse(target.result, {
        header: true
      })
      if (csv.data && csv.data.length === 0) {
        let filterArray: any = csv?.meta?.fields ? csv?.meta?.fields.filter((obj: any) => obj !== '') : []
        setList(filterArray)
        const dataArray = filterArray.toString()

        const dataNew: any = dataArray

        setGWList(`{${dataNew}}`)
      } else {
        const parsedData: any = csv?.data

        const rows: any = Object.keys(parsedData[0])

        const newArray: any = parsedData.map((obj: any) => obj[rows[0]])

        const newData = rows.concat(newArray)
        let filterArray: any = newData.filter((obj: any) => obj !== '')
        const data1: any = filterArray.map((element: any) => {
          return element.replace(/[,;]+(?=[,;]*$)/g, '')
        })

        setList(data1)

        // if (filterArray.toString().includes(';,')) {
        //   const dataArray = filterArray.toString().split(';,').join(', ')

        //   const dataNew: any = dataArray.slice(0, -1)

        //   setGWList(`{${dataNew}}`)
        // } else if (filterArray.toString().includes(';')) {
        //   const dataArray = filterArray.toString().split(';').join(', ')

        //   const dataNew: any = dataArray.slice(0, -1)
        //   setGWList(`{${dataNew}}`)
        // } else {
        const dataArray = data1.toString()

        const dataNew: any = dataArray

        setGWList(`{${dataNew}}`)

        // }
      }
    }
    reader.readAsText(e.currentTarget.files[0])

    if (e.currentTarget.files) {
      formik.setFieldValue('image', e.currentTarget.files[0])
    }
  }
  return (
    <Box className='rounded'>
      <Box
        sx={{
          width: '500px',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box textAlign={'center'} display={'flex'} justifyContent={'space-between'} padding={2}>
          <h3 style={{ fontSize: '20px', fontWeight: '600', width: '100%' }}>{title}</h3>
          <Close
            className='cursor-pointer'
            onClick={() => {
              formik.resetForm()
              handleClose()
            }}
          />
        </Box>
        <Box className='pb-3 bg-[#f7f8fe] pt-1 text-center' paddingLeft={5} paddingRight={5}>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Box marginTop={2}>
              <Typography variant='subtitle1'>Please select the .csv file</Typography>
              <Typography variant='subtitle2'>To start process of assigning devices to customer</Typography>
              <br />

              <input
                type='file'
                name='image'
                accept='.csv'
                onChange={(e: any) => handleFile(e)}
                className='p-2 rounded-[10px] w-full border-[1px] verifypin-form-input bg-white'
                style={
                  formik.errors.image
                    ? { border: `1px solid ${getColorCode('redToBlue')}` }
                    : { border: `1px solid #ccc` }
                }
              />
              {formik.errors.image && (
                <span
                  className='errorText'
                  style={{ color: getColorCode('redToBlue'), display: 'block', textAlign: 'left' }}
                >
                  {formik.errors.image}
                </span>
              )}
            </Box>

            <div className='mt-4 mb-3'>
              <Button
                type='submit'
                variant='contained'
                size='medium'
                sx={{
                  marginRight: '10px',
                  background: getColorCode('infoBlue'),
                  '&:hover': {
                    backgroundColor: getColorCode('infoBlue')
                  }
                }}
                // disabled={formik.isSubmitting}
              >
                Add Devices
              </Button>

              {/* <Button
                onClick={() => {
                  formik.resetForm()
                  handleClose()
                }}
                size='medium'
                variant='outlined'
                sx={{
                  color: getColorCode('infoBlue'),
                  borderColor: getColorCode('infoBlue')
                }}
              >
                Cancel
              </Button> */}
            </div>
          </form>
        </Box>
      </Box>
      <CustomerModal
        open={openListModal}
        handleClose={() => {
          setOpenListModal(false)
          setMessageArray([])
          setReCall(true)
          setCallDeviceDetail(true)
          handleClose()
        }}
      >
        <MessageModal
          handleClose={() => {
            setOpenListModal(false)
            setMessageArray([])
            setReCall(true)
            setCallDeviceDetail(true)
            handleClose()
          }}
          data={messageArray}
        />
      </CustomerModal>
    </Box>
  )
}
export default AddDevice
