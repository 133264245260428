import React from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// default notification
export function notifySuccess(message: any) {
  // prompt error if condition is not met

  toast.success(message, {
    position: 'bottom-right',
    autoClose: 6000, //ms
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'colored',
    progress: undefined
  })
}

export function notifyError(message: any) {
  // prompt error if condition is not met
  toast.error(message, {
    position: 'bottom-right',
    autoClose: 6000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'colored',
    progress: undefined
  })
}

export const NotificationToastContainer = () => {
  return (
    <ToastContainer
      position='top-right'
      autoClose={6000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  )
}
