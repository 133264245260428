import React from 'react'

const ChartImage: React.FC<any> = () => {
  return (
    <svg width='20' height='18' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.5714 0C19.3604 0 20 0.636674 20 1.42204V15.7208C20 16.5062 19.3604 17.1429 18.5714 17.1429C17.7824 17.1429 17.1429 16.5062 17.1429 15.7208V1.42204C17.1429 0.636674 17.7824 0 18.5714 0ZM12.8571 4.28571C13.6461 4.28571 14.2857 4.92529 14.2857 5.71429V15.7143C14.2857 16.5033 13.6461 17.1429 12.8571 17.1429C12.0681 17.1429 11.4286 16.5033 11.4286 15.7143V5.71429C11.4286 4.92529 12.0681 4.28571 12.8571 4.28571ZM7.14286 8.57143C7.93183 8.57143 8.57143 9.20726 8.57143 9.99165V15.7226C8.57143 16.507 7.93183 17.1429 7.14286 17.1429C6.35389 17.1429 5.71429 16.507 5.71429 15.7226V9.99165C5.71429 9.20726 6.35389 8.57143 7.14286 8.57143ZM1.42857 12.8571C2.21754 12.8571 2.85714 13.4862 2.85714 14.2622V15.7378C2.85714 16.5138 2.21754 17.1429 1.42857 17.1429C0.6396 17.1429 0 16.5138 0 15.7378V14.2622C0 13.4862 0.6396 12.8571 1.42857 12.8571Z'
        fill='#111111'
      />
    </svg>
  )
}

export default ChartImage
