import React, { useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { NotificationToastContainer } from '../../components/Toaster'
import { useSessionContex } from '../../SessionContex'
import SessionTimeOutModal from '../../components/SessionTimeOutModal'
import { useOnlineStatus } from '../../hook/useOnlineStatus'
import NoInternet from '../../components/NoInternet'

const PrivateRoute = () => {
  const { openModal } = useSessionContex()
  const isOnline = useOnlineStatus()

  const access_token: string | undefined | null = localStorage.getItem('access_token')

  return access_token && access_token !== undefined ? (
    <>
      {!isOnline && <NoInternet />}
      {openModal && <SessionTimeOutModal />}
      {process.env.REACT_APP_ERROR === 'true' && <NotificationToastContainer />}
      <Outlet />
    </>
  ) : (
    <Navigate to='/login' />
  )
}

export default PrivateRoute
