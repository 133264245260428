import React from 'react'
import RefreshBtn from '../../../common-images/app-logo/RefreshBtn'
import { useSessionContex } from '../../../SessionContex'
import { Tooltip, Typography } from '@mui/material'

const ReloadBtn = () => {
  const { reCall, setReCall } = useSessionContex()
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#707e8c'
          }
        }
      }}
      title={<Typography>Reload</Typography>}
    >
      <div
        className='reload-btn'
        onClick={() => {
          if (!reCall) {
            setReCall(true)
          }
        }}
        data-testid='testid_reload_button'
      >
        <RefreshBtn reCall={reCall} />
      </div>
    </Tooltip>
  )
}

export default ReloadBtn
