import React, { useEffect } from 'react'
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'
import LoginPage from './Pages/Login'
import PrivateRoute from './Pages/Login/PrivateRoute'
import Dashboard from './Pages/Dashboard'
import { Provider } from 'react-redux'
import CustomersDashboard from './Pages/Customer/Header'
import { store } from './redux/store'
import DeleteAccount from './Pages/PublicPage/DeleteAccount'
import PageNotFound from './Pages/PublicPage/PageNotFound'
import EditAlertType from './Pages/Customer/Alerts/EditAlertType'

function App() {
  useEffect(() => {
    const url = window.location.href

    if (url.endsWith('#')) {
      window.location.href = window.location.origin + window.location.pathname
    }
  }, [window])

  const router = createBrowserRouter([
    {
      path: '/',
      element: localStorage.getItem('access_token') ? <Navigate to={'/dashboard'} /> : <Navigate to={'/login'} />
    },
    {
      path: '/delete_account',
      element: <DeleteAccount />
    },
    {
      path: '/login',
      element: <LoginPage />
    },
    {
      element: <PrivateRoute />,
      children: [
        {
          path: '/dashboard/',
          element: <Dashboard />
        },
        {
          path: '/dashboard/:id',
          element: <CustomersDashboard />
        },
        {
          path: '/dashboard/:id/:email',
          element: <CustomersDashboard />
        },
      ]
    },
    {
      path: '*',
      element: <PageNotFound />
    }
  ])

  return (
    <>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </>
  )
}

export default App
