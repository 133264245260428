import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { getColorCode } from '../../../../utils/helper'
import moment from 'moment'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)

const Active = ({ data }: any) => {
  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()

  // online_options
  const active_chart_data =
    data?.map((item: any) => {
      // var localtime = moment.utc(item.timestamp).local().format(`${process.env.REACT_APP_DATE_FORMAT} HH:mm:ss`)
      var localtime = dayjs.utc(item.timestamp, 'YYYYMMDDTHH:mm:ss').tz(timeZone).toDate()
      return [localtime?.getTime(), item.is_active === null ? item.is_active : item.is_active ? 1 : 0]
    }) || []
  const channel_options = {
    chart: {
      type: 'areaspline'
      // height: 210
      // marginTop: 50,
      // marginBottom: 40
    },
    title: {
      text: 'Active',
      align: 'left'
    },
    subtitle: {
      text: null
    },
    xAxis: {
      type: 'datetime',
      tickInterval: 1 * 60 * 60 * 1000, // 3 hours in milliseconds
      labels: {
        formatter: function (this: any) {
          return moment(this.value).format('hh:mm A')
        }
      },
      dateTimeLabelFormats: {
        hour: '%I:%M %p', // Format for hours
        day: '%d/%m %H:%M' // Format for days
      }
    },
    // xAxis: {
    //   type: 'datetime',
    //   tickInterval: 3 * 60 * 60 * 1000, // 3 hours in milliseconds
    //   dateTimeLabelFormats: {
    //     hour: '%I:%M %p', // Format with AM/PM
    //     day: '%I:%M %p' // Format with AM/PM
    //   }
    // },
    yAxis: {
      title: {
        text: ''
      },
      gridLineColor: 'transparent',
      lineWidth: 1,
      labels: {
        formatter: function (this: any) {
          return this.value === 1 ? 'Active' : 'Idle'
        }
      },
      gridLineWidth: 0,
      min: 0,
      max: 1,
      tickInterval: 1
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      floating: true,
      borderWidth: 0,
      backgroundColor: '#f0f8ff00'
    },
    plotOptions: {
      area: {
        dataLabels: {
          enabled: false
        }
      }
    },
    series: [
      {
        name: 'Active',
        data: active_chart_data.map((i: any) => {
          return i[1] === 1 ? i : [i[0], null]
        }),
        color: getColorCode('chartRedDarkPink')
      },
      {
        name: 'Idle',
        data: active_chart_data.map((i: any) => {
          return i[1] === 0 ? i : [i[0], null]
        }),
        color: getColorCode('chartGreenPink')
      }
    ],
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        if (this.y === null) return
        const timestamp =
          moment(this.x).local().fromNow() +
          '(' +
          moment(this.x).local().format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm:ss A`) +
          ')'
        return (
          '<div class="custom-tooltip">' +
          '<span><b>' +
          'Status' +
          ': </b></span>' +
          '<span>' +
          `${this.y ? 'Active' : 'Idle'}` +
          '</span><br/>' +
          '<span> <b>when: </b>' +
          timestamp +
          '</span>' +
          '</div>'
        )
      }
    }
  }
  return <HighchartsReact highcharts={Highcharts} options={channel_options} />
}

export default Active
