import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'
import Skeleton from '@mui/material/Skeleton'
import { SearchStatus, User, Warning2 } from 'iconsax-react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useGlobalSearch } from '../../../hook/search/useGlobalSearch'
import useClickOutside from '../../../hook/useClickOutside'
import { useDispatch } from 'react-redux'
import { getColorCode } from '../../../utils/helper'

const GlobalSearchInput: React.FC = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const dispatch: any = useDispatch()

  const globalSearch = useRef<HTMLDivElement | null>(null)
  const [inputValue, setInputValue] = useState('')
  const [isFocused, setIsFocused] = useState(false)
  const [inputArray, setInputArray]: any = useState([])
  const [deviceStatsLoading, setdeviceStatsLoading] = useState<boolean>(false)

  const placeholderText = isFocused ? 'Type 4+ characters to search...' : 'Search anything..'

  const { data, refetch }: any = useGlobalSearch(inputValue)
  useEffect(() => {
    refetch()
  }, [])
  useEffect(() => {
    if (inputValue.length === 0 || inputValue.length >= 4) {
      refetch()
    }
  }, [inputValue])

  useEffect(() => {
    if (!!data && data.length > 0 && filterAndHighlight(data).length > 0) setInputArray(filterAndHighlight(data))
  }, [data])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    if (value !== '') {
      setInputValue(value)
    } else {
      setInputValue('')
    }
  }

  const handleClick = (option: any) => {
    setInputValue('')
    setIsFocused(false)
    localStorage.setItem('gwuid', option.gw_uid.replaceAll('<mark>', '').replaceAll('</mark>', ''))

    navigate(`/dashboard/${option.gw_uid.replaceAll('<mark>', '').replaceAll('</mark>', '')}`)
    dispatch({
      type: 'SET_CUSTOMER_GWUID_REQUEST',
      payload: option.gw_uid.replaceAll('<mark>', '').replaceAll('</mark>', '')
    })
  }

  // highlight matching results
  const filterAndHighlight = (suggestions: any) => {
    try {
      const regex = new RegExp(`(${inputValue})`, 'gi')
      return suggestions.map((suggestion: any) => {
        var gw_uid =
          suggestion && suggestion.gw_uid ? suggestion.gw_uid.slice(0, 5) + '...' + suggestion.gw_uid.slice(-5) : ''
        const highlightedEmail = suggestion.email.replace(regex, '<mark>$1</mark>')
        const GwUid = suggestion.gw_uid.replace(regex, '<mark>$1</mark>')
        const highlightedGwUid = gw_uid.replace(regex, '<mark>$1</mark>')
        return {
          ...suggestion,
          email: highlightedEmail,
          gw_uidLabel: highlightedGwUid,
          gw_uid: GwUid
        }
      })
    } catch (error) {
      return suggestions
    }
  }

  const filterAndHighlightAlerts = (suggestions: any) => {
    try {
      const regex = new RegExp(`(${inputValue})`, 'gi')
      return suggestions.map((suggestion: any) => {
        const highlightedEmail = suggestion.email.replace(regex, '<mark>$1</mark>')
        const highlightedGwUid = suggestion.alert_type.replace(regex, '<mark>$1</mark>')
        return {
          ...suggestion,
          email: highlightedEmail,
          alert_type: highlightedGwUid
        }
      })
    } catch (error) {
      // console.error('Invalid regular expression:', error)
      return suggestions
    }
  }

  const filteredArrayData = inputArray
  // .filter((res: any) => {
  //   return res.dev_mesh_role === 'CAP'
  // })

  const onlineFilter = filteredArrayData.filter((res: any) => {
    return res.is_online
  })

  const offFilter = filteredArrayData.filter((res: any) => {
    return !res.is_online
  })

  const filteredArray = [...onlineFilter, ...offFilter]

  const handleClickOutside = () => {
    setInputValue('')
  }

  useClickOutside(globalSearch, handleClickOutside)

  return (
    <div className={styles.autocomplete} ref={globalSearch}>
      <div className={styles.input_container}>
        <input
          type='text'
          value={inputValue}
          onChange={handleInputChange}
          onBlur={() => {
            if (inputValue === '') setIsFocused(false)
          }}
          onFocus={() => {
            setIsFocused(true)
          }}
          className={styles.input}
          placeholder={placeholderText}
          style={{
            borderRadius: inputValue !== '' && isFocused ? `15px 15px 0px 0px` : '15px'
          }}
          data-testid='testid_search_field'
        />
        <SearchStatus className={styles.search_glass} color={isFocused ? getColorCode('purpleBlue') : '#000000'} />

        {!deviceStatsLoading ? (
          inputValue !== '' && isFocused && data.length !== 0 ? (
            <ul className={styles.options}>
              {filteredArray.map((res: any) => {
                return (
                  <>
                    <div style={{ borderTop: '2px solid rgb(239,242,252)' }}>
                      <li key={res.gw_uid} className={styles.option} onClick={() => handleClick(res)}>
                        <div className={styles.left_option_wrapper}>
                          <div>
                            <User size={18} />
                          </div>
                          <div>
                            <span className={styles.email_with_icon}>
                              <span
                                className={styles.mark}
                                dangerouslySetInnerHTML={{
                                  __html: res.email || '-'
                                }}
                              ></span>
                            </span>
                            <span
                              className={styles.gw}
                              dangerouslySetInnerHTML={{
                                __html: res.gw_uidLabel
                              }}
                            ></span>
                          </div>
                        </div>
                        {res.is_online ? (
                          <span className={styles.alert_pill}>Online</span>
                        ) : (
                          <span className={styles.user_pill}>Offline</span>
                        )}
                        {/* <span
                          className={styles.user_pill}
                          style={{
                            borderColor: res.dev_mesh_role === 'CAP' ? '#FF974C' : '#6c83ff'
                          }}
                        >
                          {res.dev_mesh_role === 'CAP' ? 'Master' : 'Satellite'}
                        </span> */}
                      </li>
                    </div>
                  </>
                )
              })}
            </ul>
          ) : isFocused && inputValue.length >= 1 && inputValue.length <= 4 ? (
            <ul className={styles.options}>
              <li className={styles.option} style={{ borderTop: '1px solid rgb(239,242,252)' }}>
                Searching...
              </li>
            </ul>
          ) : isFocused && inputValue !== '' ? (
            <ul className={styles.options}>
              <li className={styles.option} style={{ borderTop: '1px solid rgb(239,242,252)' }}>
                No results found
              </li>
            </ul>
          ) : (
            <></>
          )
        ) : (
          <ul className={styles.options}>
            <Skeleton variant='text' animation='wave' width={800} sx={{ marginLeft: '-10px' }} height={32} />
          </ul>
        )}
      </div>
    </div>
  )
}

export default GlobalSearchInput
