import React, { useContext, Fragment } from 'react'
import ReloadBtn from '../ReloadBtn'
import GlobalSearchInput from '../global-search-bar'
import DropDown from '../DropDown'
import { BreadcrumbContext } from '../../../contexts/BreadcrumbContext'
import LogOutBtn from '../Logout'
import { useLocation, useParams } from 'react-router-dom'
import { Tooltip, Typography } from '@mui/material'

const RightSideBar = () => {
  const { filters = [], disabled = true }: any = useContext(BreadcrumbContext)
  const { email } = useParams()
  const location = useLocation()

  return (
    <div className='rightSideBar-wrapper' key={1}>
      {!email &&
        location.search === '' &&
        filters?.map((item: any, index: number) => {
          return (
            <Fragment key={index}>
              <DropDown
                disable={disabled}
                key={index}
                maxWidth={'210px'}
                options={item.options}
                label={item.label}
                tags={item?.tags}
                filterKey={item.filterKey}
                onClick={item.action}
                activeOption={item?.activeOption}
                preventActive={item?.preventActive}
              />
            </Fragment>
          )
        })}
     
        <ReloadBtn />
      
      <GlobalSearchInput />
      <LogOutBtn />
    </div>
  )
}

export default RightSideBar
