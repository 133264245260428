import { ArrowDown, ArrowDown2, ArrowUp2 } from 'iconsax-react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from './dropdown-menu'
import { transformToCamelCase } from '../../../utils/helper'
import DownSideArrow from '../../../common-images/app-logo/downside-arrow'
import { Box } from '@mui/material'

interface DropDownProps {
  disable?: any
  options: any
  filterKey: string
  label?: string
  onClick?: any
  activeOption?: any
  tags?: any
  preventActive?: boolean
  maxWidth?: string
}

const DropDown = ({
  disable,
  options,
  filterKey,
  label,
  onClick = () => {},
  activeOption,
  tags,
  preventActive = false,
  maxWidth = 'none'
}: DropDownProps) => {
  const navigate = useNavigate()
  const [activeValue, setActiveValue] = useState<string>('')

  useEffect(() => {
    if (label !== 'Open' && activeOption) {
      setActiveValue(label || activeOption?.label)
    } else {
      setActiveValue('')
    }
  }, [label || activeOption])

  const handleClick = (val: any) => {
    onClick(filterKey, val)
    setActiveValue(val.label)
    updateStatusQueryParam(val)
  }

  const updateStatusQueryParam = (newStatus: any) => {
    navigate(`/dashboard/${newStatus.Id}`)
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className='dropdown-btn flex items-center'
        disabled={disable}
        style={{ minWidth: 'auto', maxWidth: maxWidth }}
      >
        <span className='dropdown-value'>{activeValue}</span>
        <span className='down-arrow'>
          <DownSideArrow />
        </span>
      </DropdownMenuTrigger>
      <DropdownMenuContent className='drop-down' aria-disabled={disable}>
        {options.map((option: any, index: number) => {
          return (
            <DropdownMenuItem
              className='drop-down-option'
              key={`option_${index}_${option}`}
              onClick={() => handleClick(option)}
              // disabled={disable}
            >
              {option.label}
              <div className='d-flex justify-center align-center'>{tags?.length > 0 && tags[index]}</div>
            </DropdownMenuItem>
          )
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default DropDown
