import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import CyberLogs from './cyberLog'
import IotLogs from './iotLog'
import CustomeTabs from '../../../../components/CustomeTabs'
import ParentalLogs from './parentalLog'
import './activity.scss'
import SupportAgentLog from './supportAgentLog'
import DeviceLog from './deviceLog'

const tabsList = [
  {
    label: 'Device',
    testId:'testid_device_logs',
    tab: (
      <>
        <DeviceLog />
      </>
    )
  },
  {
    label: 'Support Agent',
    testId:'testid_support_logs',
    tab: (
      <>
        <SupportAgentLog />
      </>
    )
  }
  // {
  //   label: 'Cyber',
  //   tab: (
  //     <>
  //       <CyberLogs />
  //     </>
  //   )
  // },
  // {
  //   label: 'Parental',
  //   tab: (
  //     <>
  //       <ParentalLogs />
  //     </>
  //   )
  // }
  // {
  //   label: 'IoT',
  //   tab: (
  //     <>
  //       <IotLogs />
  //     </>
  //   )
  // }
]
const CustomersActivities = () => {
  const [tab, setTab] = useState(tabsList)
  useEffect(() => {
    if (process.env.REACT_APP_REALM === 'Hitron-staging') {
      const newTab: any = {
        label: 'Parental',
        testId:'testid_parental_logs',
        tab: (
          <>
            <ParentalLogs />
          </>
        )
      }
      setTab([...tabsList, newTab])
    } else {
      setTab(tabsList)
    }
  }, [])

  return (
    <Card className='info-card-cls activity-card '>
      <CardHeader className='info-header-cls' title='Activities' />
      <CustomeTabs tabsList={tab} divider={<Divider className='' />} className='activity-section' />
    </Card>
  )
}

export default CustomersActivities
