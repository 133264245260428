import * as React from 'react'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import { Box, LinearProgress, linearProgressClasses, styled, Tooltip } from '@mui/material'
import TablePagination from '@mui/material/TablePagination'
import { ChangeEvent, useEffect, useState } from 'react'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import './styles.scss'
import { useNavigate } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { ArrowDown, ArrowUp } from 'iconsax-react'
import { Column } from '../../models/CustomerTable'
import { Customer } from '../../models/Xcpem'
import { CustomTable, CustomTableHeaderRow, CustomTableRow } from './devices-tables'
import { getColorCode, getUpTime, transformToupTime } from '../../utils/helper'
import { useScreenWidth } from '../../hook/useScreenWidth'
import EmptyData from '../../common-images/app-logo/customer-images/EmptyData'
import { RootState } from '../../redux/deviceStatistics/types'
import NoDataFound from '../NoDataFound'
import Loader from '../Loader'
import {
  getDeviceCount,
  getDeviceFilterStatistics,
  getDeviceStatistics,
  getDeviceStatisticsCount
} from '../../redux/deviceStatistics/deviceStatistics'
import moment from 'moment'
import TableHeader from './tableHeader'
import Provisioning from './Provisioning'
import { getCustomerStatisticsCount } from '../../redux/customerStatistics/customerStatistics'
import { useSessionContex } from '../../SessionContex'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 10,
  overflow: 'hidden',
  '& .MuiLinearProgress-bar': {
    borderRadius: 10
  }
}))

const StyledTableCell = styled(TableCell)`
  && {
    font-size: 16px;
    background: transparent;
    color: #282828 !important;
    font-weight: 500;
    padding: 9px 16px;
  }
`
const StyledTableLinkCell = styled(TableCell)`
  && {
    font-size: 16px;
    background: transparent;
    text-decoration: underline;
    font-weight: 500;
    padding: 9px 16px;
  }
`

const DeviceTable = ({ filteringValue, deviceStats, deviceStatsLoading, count }: any) => {
  const navigate = useNavigate()
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch()
  const screenWidth = useScreenWidth()
  const [loading, setLoading] = useState(true)
  const filterLoading = useSelector((state: RootState) => state.deviceStatistics.filterLoading)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [provistionId, setProvistionId] = useState('')
  const [offset, setOffset] = useState<number>(0)
  const [limit, setLimit] = useState<number>(10)
  const [filterTypes, setFilterTypes] = useState({ cell: '', type: '' })
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [width, setWidth] = useState<any>({
    small: '80px',
    normal: '120px',
    regular: '150px',
    large: '180px',
    extraLarge: '200px'
  })

  const [columns, setColumns] = useState<Column[]>([
    // {
    //   id: 'health',
    //   label: 'Health',
    //   minWidth: width.extraLarge,
    //   align: 'left',
    //   filter: ''
    // },
    {
      id: 'status',
      label: 'Status',
      minWidth: width.small,
      align: 'left',
      filter: 'is_online',
      display: true
    },
    {
      id: 'email',
      label: 'Customer ID',
      minWidth: width.extraLarge,
      align: 'left',
      filter: 'email',
      display: true
    },
    {
      id: 'deviceType',
      label: 'Device Type',
      minWidth: width.large,
      align: 'left',
      filter: 'model',
      display: true
    },
    {
      id: 'getwayTitle',
      label: 'Gateway Title',
      minWidth: width.normal,
      align: 'left',
      filter: 'title',
      display: true
    },
    {
      id: 'deviceRole',
      label: 'Role',
      minWidth: width.normal,
      align: 'left',
      filter: 'dev_mesh_role',
      display: true
    },

    {
      id: 'lastSeen',
      label: 'Last Seen',
      minWidth: width.extraLarge,
      align: 'left',
      filter: 'last_seen',
      display: true
    },

    {
      id: 'gwUid',
      label: 'Gateway UID',
      minWidth: width.extraLarge,
      align: 'left',
      filter: 'gw_uid',
      display: true
    },
    {
      id: 'macId',
      label: 'WAN MAC',
      minWidth: width.extraLarge,
      align: 'left',
      filter: 'wan_mac',
      display: true
    },
    {
      id: 'upTime',
      label: 'Up Time',
      minWidth: width.large,
      align: 'left',
      filter: 'uptime',
      display: true
    },
    {
      id: 'fwVersion',
      label: 'FW Version',
      minWidth: width.large,
      align: 'left',
      filter: 'fw_version',
      display: true
    },
    // {
    //   id: 'problems',
    //   label: 'Problems',
    //   minWidth: width.small,
    //   align: 'left',
    //   filter: 'problems'
    // },
    // {
    //   id: 'connectedDevices',
    //   label: 'Connected D.',
    //   minWidth: width.large,
    //   align: 'left',
    //   filter: ''
    // },
    {
      id: 'ottSerial',
      label: 'OTT Serial',
      minWidth: width.large,
      align: 'left',
      filter: 'ott_serial',
      display: process.env.REACT_APP_MODE !== 'hitron' ? true : false
    }
    // {
    //   id: 'devSerial',
    //   label: 'Dev Serial',
    //   minWidth: width.large,
    //   align: 'left',
    //   filter: 'dev_serial',
    //   display: true
    // }

    // {
    //   id: 'healthStatus',
    //   label: '',
    //   minWidth: 50,
    //   align: 'left'
    // }
  ])
  useEffect(() => {
    if (!deviceStatsLoading) {
      setLoading(false)
    }
  }, [deviceStatsLoading])
  //for add link in notRegister devices table
  // useEffect(() => {
  //   if (filteringValue === 'notRegistered') {
  //     columns.splice(1, 0, {
  //       id: 'account',
  //       label: 'Account',
  //       minWidth: width.small,
  //       align: 'center'
  //     })
  //     setColumns(columns)
  //   } else {
  //     const updatedColumns = columns.filter((item: any) => {
  //       return item.id !== 'account'
  //     })
  //     setColumns([...updatedColumns])
  //   }
  // }, [filteringValue])
  const fun = async () => {
    const res: any = await dispatch(getDeviceStatistics({ filter: dispatchCall(filteringValue) || '', limit, offset }))
    if (res === 401) {
      !openModal && setOpenModal(true)
    }
  }
  useEffect(() => {
    if (reCall) {
      setLoading(true)
      if (offset === 0 && limit === 10) {
        fun()
      } else {
        setOffset(0)
        setLimit(10)
        setCurrentPage(0)
      }
    }
  }, [reCall])

  useEffect(() => {
    if (!(filterLoading || loading || deviceStatsLoading)) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [filterLoading, loading, deviceStatsLoading])

  useEffect(() => {
    setLoading(true)
    if (offset === 0 && limit === 10) {
      fun()
    } else {
      setOffset(0)
      setLimit(10)
      setCurrentPage(0)
    }
  }, [filteringValue])

  useEffect(() => {
    if (!screenWidth) return
    if (screenWidth > 2500) {
      setWidth({ small: '140px', normal: '180px', regular: '210px', large: '240px', extraLarge: '260px' })
    } else if (screenWidth > 2000) {
      setWidth({ small: '100px', normal: '140px', regular: '170px', large: '200px', extraLarge: '220px' })
    } else {
      setWidth({
        small: '50px',
        normal: '120px',
        regular: '150px',
        large: '180px',
        extraLarge: '200px'
      })
    }
  }, [screenWidth])

  useEffect(() => {
    fun()
  }, [limit, offset])

  useEffect(() => {
    //fil
    if (!!filterTypes.cell && ['asc', 'desc'].includes(filterTypes.type)) {
      const fun = async () => {
        const res: any = await dispatch(
          getDeviceFilterStatistics({
            filter: `${dispatchCall(filteringValue) || ''}&order=${filterTypes.cell}.${filterTypes.type}`,
            limit,
            offset
          })
        )
        if (res === 401) {
          !openModal && setOpenModal(true)
        }
      }
      fun()
    } else if (filterTypes.type === 'reset') {
      const fun = async () => {
        const res: any = await dispatch(
          getDeviceFilterStatistics({ filter: `${dispatchCall(filteringValue) || ''}`, limit, offset })
        )
        if (res === 401) {
          !openModal && setOpenModal(true)
        }
      }
      fun()
    }
  }, [filterTypes])

  useEffect(() => {
    dispatch({ type: 'SET_DEVICE_ACTIVE_TAB_REQUEST', payload: 0 })
  }, [])

  const customerRefetch = async () => {
    const res: any = await dispatch(getDeviceStatistics({ filter: dispatchCall(filteringValue) || '', limit, offset }))
    if (res === 401) {
      !openModal && setOpenModal(true)
    }
    const res1: any = await dispatch(getDeviceCount())
    if (res1 === 401) {
      !openModal && setOpenModal(true)
    }
    const res2: any = await dispatch(getDeviceStatisticsCount())
    if (res2 === 401) {
      !openModal && setOpenModal(true)
    }
    const res3: any = await dispatch(getCustomerStatisticsCount())
    if (res3 === 401) {
      !openModal && setOpenModal(true)
    }
  }

  const EmptyMessage = (filterValue: string) => {
    switch (filterValue) {
      case 'AllDevices':
        return 'There are No Devices that are registered at the moment'
      case 'AllCustomer':
        return 'There are No Customers / MSOs registered at the moment'
      case 'Online':
        return 'There are No Online devices at the moment'
      case 'Offline':
        return 'There are No Offline devices at the moment'
      case 'notRegistered':
        return 'There are No non registered devices at the moment'
      case 'needUpdate':
        return 'There are No devices which requires FW updates at the moment'
      default:
        return 'No Data found'
    }
  }

  const dispatchCall = (filterValue: string) => {
    switch (filterValue) {
      case 'AllDevices':
        return ''
      case 'Online':
        return '&is_online=eq.true'
      case 'Offline':
        return '&is_online=eq.false'
      case 'notRegistered':
        return '&and=(email.eq.)'
      case 'needUpdate':
        return '&up_to_date=eq.false'
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setCurrentPage(newPage)
    const newOffset = newPage * limit
    setOffset(newOffset)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value))
    setCurrentPage(0)
    setOffset(0)
  }

  const handleFilter = (cell: string, type: string) => {
    setFilterTypes({ cell: cell, type: type })
  }

  const setnavigation = (device: any) => {
    localStorage.setItem('gwuid', device.gw_uid)
    navigate(`/dashboard/${device.gw_uid}`)
  }
  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()

  return (
    <div style={{ overflow: 'hidden', width: '100%' }} className='device_table_wrapper'>
      <TableContainer
        sx={{
          // maxHeight: 640, // For Firefox
          // scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          overflowX: 'auto',

          // For Webkit browsers
          '&::-webkit-scrollbar': {
            width: 0,
            height: 0
          }
        }}
      >
        <CustomTable className='table-wrapper' stickyHeader aria-label='sticky table'>
          {deviceStatsLoading ? (
            <div className='w-full table-wrapper_loader'>
              <Loader />
            </div>
          ) : deviceStats.length !== 0 ? (
            <>
              <TableHead>
                <CustomTableHeaderRow sx={{ borderRadius: 15, height: '60px' }}>
                  {columns.map((column: Column, key: any) => {
                    return (
                      column.display && (
                        <TableHeader column={column} onClick={handleFilter} filterType={filterTypes} key={key} />
                      )
                    )
                  })}
                </CustomTableHeaderRow>
              </TableHead>
            </>
          ) : (
            <div
              className='d-flex justify-center items-center text-lg mt-2'
              style={{ color: getColorCode('redToBlue') }}
            >
              {/* <NoDataFound image={<EmptyData />} message={EmptyMessage(filteringValue)} /> */}

              {EmptyMessage(filteringValue)}
            </div>
          )}
          {filterLoading && loading ? (
            <div className='w-full table-wrapper_loader'>
              <Loader />
            </div>
          ) : (
            <TableBody>
              {!deviceStatsLoading &&
                !loading &&
                deviceStats.length !== 0 &&
                deviceStats.map((device: Customer, index: number) => {
                  const colorCode = device.is_online ? getColorCode('greenToGreen') : getColorCode('redToGray')

                  return (
                    <CustomTableRow
                      styledcolor={colorCode}
                      key={`device_row${index}`}
                      onClick={() => setnavigation(device)}
                    >
                      {/* <StyledTableCell>
                        <div className='d-flex align-center'> */}
                      {/* <QoEPerformanceChart /> */}
                      {/* <Box sx={{ flexGrow: 1, marginRight: '15px', width: '80px', maxWidth: '80px' }}>
                            <BorderLinearProgress
                              variant='determinate'
                              sx={{
                                height: '15px',
                                '.MuiLinearProgress-bar': {
                                  backgroundColor: colorCode
                                },
                                '& .MuiLinearProgress-barColorSecondary': {
                                  backgroundColor: 'blue' // Set your desired background color here
                                },
                                [`&.${linearProgressClasses.colorPrimary}`]: {
                                  backgroundColor: '#EFF2FC'
                                }
                              }}
                              value={device.is_online ? 100 : 25}
                              className='Progressbar mt-[5px]'
                            />
                          </Box> */}
                      {/* <span className='ml-3 w-[150px]'>{device.is_online ? 'Excellent' : 'Poor'}</span>
                        </div>
                      </StyledTableCell> */}
                      <StyledTableCell sx={{ textAlign: 'left' }}>
                        <FiberManualRecordIcon
                          sx={{
                            color: colorCode,
                            verticalAlign: 'bottom',
                            width: '15px'
                          }}
                        />
                      </StyledTableCell>
                      {filteringValue === 'notRegistered' ? (
                        <StyledTableLinkCell
                          onClick={(e: any) => {
                            e.stopPropagation()
                            setProvistionId(device.gw_uid || '')
                            setIsModalOpen(true)
                          }}
                          style={{ color: getColorCode('infoBlue') }}
                        >
                          Click to Provision
                        </StyledTableLinkCell>
                      ) : (
                        <StyledTableCell>{device.email || '-'}</StyledTableCell>
                      )}
                      <StyledTableCell>{device.model}</StyledTableCell>
                      <StyledTableCell>{device.title}</StyledTableCell>
                      <StyledTableCell>{device.dev_mesh_role === 'CAP' ? 'Master' : 'Satellite'}</StyledTableCell>

                      <StyledTableCell>
                        {/*<Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: '#707e8c'
                          
                        }
                      }
                    }} placement='top' title={moment.utc(device.last_seen).local().fromNow()}> */}
                        <Tooltip
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: '#707e8c'
                              }
                            }
                          }}
                          placement='top'
                          title={moment.utc(device.last_seen).local().fromNow()}
                        >
                          <>
                            {moment
                              .utc(device.last_seen)
                              .local()
                              .format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm A`)}

                            {/* {moment.tz(device.last_seen).local().format(`${process.env.REACT_APP_DATE_FORMAT} HH:mm`)} */}
                          </>
                        </Tooltip>
                      </StyledTableCell>

                      <StyledTableCell> {`${device.gw_uid.slice(0, 8)}...${device.gw_uid.slice(-8)}`}</StyledTableCell>
                      <StyledTableCell>{device.wan_mac}</StyledTableCell>
                      <StyledTableCell>{getUpTime(device.uptime as unknown as number)}</StyledTableCell>
                      <StyledTableCell>
                        <div className='d-flex'>
                          {/* {device.fw_version !== '' && device.fw_version !== null ? (
                            device.up_to_date ? (
                              <ArrowUp
                                size='16'
                                color={
                                  process.env.REACT_APP_COLOR === 'hitron' ? getColorCode('greenToGreen') : colorCode
                                }
                                className='fw-arrow'
                              />
                            ) : (
                              <ArrowDown
                                size='16'
                                color={
                                  process.env.REACT_APP_COLOR === 'hitron' ? getColorCode('redToYellow') : colorCode
                                }
                                className='fw-arrow'
                              />
                            )
                          ) : (
                            ''           
                          )} */}
                          <span className='ms-1'>{device.fw_version || '-'}</span>
                        </div>
                      </StyledTableCell>
                      {/* <StyledTableCell>
                        <div className='affected_devices' style={{ background: colorCode }}>
                          {device.problems}
                        </div>
                      </StyledTableCell> */}
                      {/* <StyledTableCell>-</StyledTableCell> */}
                      {process.env.REACT_APP_MODE !== 'hitron' && (
                        <StyledTableCell>{device.ott_serial !== '' ? device.ott_serial : '-'}</StyledTableCell>
                      )}
                      {/* <StyledTableCell>{device.dev_serial !== '' ? device.dev_serial : '-'}</StyledTableCell> */}
                    </CustomTableRow>
                  )
                })}
            </TableBody>
          )}
        </CustomTable>
      </TableContainer>
      {isModalOpen && (
        <Provisioning
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          provistionId={provistionId}
          customerRefetch={customerRefetch}
        />
      )}

      {!deviceStatsLoading && count && count !== 0 ? (
        <TablePagination
          className='customers_footer'
          sx={{ marginRight: 0 }}
          rowsPerPageOptions={[6, 10, 20, 50]}
          component='div'
          count={count}
          rowsPerPage={limit}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default DeviceTable
