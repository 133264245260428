// import TimeSupervisionChart from '../../../Charts/time-supervision-chart/time-supervision-chart'
import { useTimeSupervision } from '../../../../../hook/parental-control/useParental'
import { getColorCode } from '../../../../../utils/helper'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import moment from 'moment/moment'
import TablePagination from '@mui/material/TablePagination'
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import '../styles.scss'
import TimeSupervisionChart from '../../../../Charts/time-supervision-chart'
import LoadingSpinner from '../../../../../common-images/app-logo/LoadingSpinner/LoadingSpinner'
import { useSessionContex } from '../../../../../SessionContex'

interface Props {
  email: string
  isCard?: boolean
  filteringDevice?: any
  dataFilter?: any
  fromDates?: any
}

const TimeSuperVisionCommon: FC<Props> = ({ email, isCard, filteringDevice, dataFilter, fromDates }) => {
  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()

  // const [hours] = useState<number>(dayjs().tz(timeZone).diff(dayjs().tz(timeZone).startOf('day'), 'hour'))
  const [fromDate, setFromDate] = useState<any>(
    moment.utc(moment.tz(moment().startOf('day'), timeZone)).format('YYYYMMDDTHH:mm:ss')
  )
  const [filterLabel, setFilterLabel] = useState<string>('today')

  const { data, isLoading, refetch, isFetching, error } = useTimeSupervision(
    fromDates ? fromDates : fromDate,
    email,
    filteringDevice
  )

  // pagination
  const [offset, setOffset] = useState<number>(0)
  const [limit, setLimit] = useState<number>(6)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [newData, setNewData] = useState<any>([])

  useEffect(() => {
    if (data) {
      if (data === 401) {
        !openModal && setOpenModal(true)
      } else {
        setNewData(data?.results.activities)
      }
    } else {
      setNewData([])
    }
  }, [data])

  const handleChangePage = (event: unknown, newPage: number) => {
    setCurrentPage(newPage)
    const newOffset = newPage * limit
    setOffset(newOffset)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    // setRowsPerPage(+event.target.value)
    setLimit(parseInt(event.target.value))
    setCurrentPage(0)
    setOffset(0)
  }
  // pagination

  const handleFilterChange = (e: any) => {
    if (e.target.value === 'thisMonth') {
      setFromDate(moment.utc(moment.tz(moment().subtract(1, 'month'), timeZone)).format('YYYYMMDDTHH:mm:ss'))
    } else if (e.target.value === 'thisWeek') {
      setFromDate(moment.utc(moment.tz(moment().subtract(7, 'days'), timeZone)).format('YYYYMMDDTHH:mm:ss'))
    } else if (e.target.value === 'today') {
      setFromDate(moment.utc(moment.tz(moment().startOf('day'), timeZone)).format('YYYYMMDDTHH:mm:ss'))
    }
    setFilterLabel(e.target.value)
  }

  useEffect(() => {
    setCurrentPage(0)
    setOffset(0)
    refetch()
  }, [fromDate, filteringDevice, dataFilter])
  useEffect(() => {
    if (reCall) {
      if (currentPage !== 0 || offset !== 0) {
        setCurrentPage(0)
        setOffset(0)
      } else {
        refetch()
      }
    }
  }, [reCall])

  useEffect(() => {
    if (!isFetching) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [isFetching])

  // const filteredData =
  //   filteringDevice && filteringDevice !== '*'
  //     ? data?.results.activities.filter((item: any) => item.device_id === filteringDevice)
  //     : data?.results.activities

  const CartData = [
    {
      name: 'Today',
      value: 'today'
    },
    {
      name: 'Last 7 Days',
      value: 'thisWeek'
    },
    {
      name: 'Last 30 Days',
      value: 'thisMonth'
    }
  ]
  const calculateDuration = (end_datetime: any, start_datetime: any) => {
    // Convert timestamps to milliseconds
    const startTime = start_datetime * 1000
    const endTime = end_datetime * 1000

    // Calculate the difference in milliseconds
    const durationInMilliseconds = endTime - startTime

    // Convert the duration to hours and minutes
    const hours = Math.floor(durationInMilliseconds / 3600000)
    const minutes = Math.floor((durationInMilliseconds % 3600000) / 60000)
    const seconds = Math.floor((durationInMilliseconds % 60000) / 1000)

    return `${hours !== 0 ? `${hours}h ` : ''} ${minutes !== 0 ? `${minutes}m ` : ''} ${seconds}s`
  }

  return (
    <div
      className={`time-supervision-wrapper bg-white rounded-[10px] min-h-[532px] ${
        isCard ? 'iscard' : 'w-full'
      } pt-[26px]  px-[28px]`}
    >
      {isCard && (
        <div className='inline-flex w-full justify-between items-center time-supervision-inner-wrapper'>
          <span className='text-[24px] font-sf-bold time-supervision-title'>Time Supervision ( in minutes )</span>
          {/* <select className='px-3 py-2 border-[1px] border-[#EFF2FC] rounded-[20px]'
                            defaultValue={filterLabel} onChange={(e) => handleFilterChange(e)}>
                        <option value='thisMonth'>This month</option>
                        <option value='thisWeek'>This week</option>
                        <option value='today'>Today</option>
                    </select> */}
          {/* <DropdownMenu>
            <DropdownMenuTrigger className='dropdown-btn flex'>
              {filterLabel}
              <span className='down-arrow'>
                <DownSideArrow />
              </span>
            </DropdownMenuTrigger>
            <DropdownMenuContent className='drop-down'>
              {CartData.map((option: any, index: number) => {
                return (
                  <DropdownMenuItem
                    className='drop-down-option'
                    key={`option_${index}_${option}`}
                    onClick={(e: any) => handleFilterChange(option)}
                  >
                    {option.name}
                  </DropdownMenuItem>
                )
              })}
            </DropdownMenuContent>
          </DropdownMenu> */}
          <div className='w-40 mt-2'>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>Filter</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={filterLabel}
                label='Filter'
                onChange={handleFilterChange}
                className='text-uppercase'
              >
                {CartData.map((option: any, index: number) => {
                  return (
                    <MenuItem value={option.value} className='text-uppercase' key={`option_${index}_${option}`}>
                      {option.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>
        </div>
      )}

      <div className='w-full mt-[12px] pb-[44px] chart-main-block' style={{ height: '60%' }}>
        {/* {!isFetching && data && (
            )} */}
        {isFetching ? (
          <div
            style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <LoadingSpinner />
          </div>
        ) : data?.results?.time_chart_items.length === 0 || error !== null ? (
          <Box color={getColorCode('redToBlue')} textAlign={'center'}>
            Time chart data not found
          </Box>
        ) : (
          <>
            <TimeSupervisionChart data={data?.results?.time_chart_items} />
          </>
        )}
      </div>

      {!isCard && (
        <>
          <table className='pc-table'>
            <thead>
              <tr>
                <td>Device name</td>
                <td>Duration</td>
                <td>Start time</td>
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td colSpan={3}>
                    <Box display={'flex'} justifyContent={'center'}>
                      <LoadingSpinner />
                    </Box>
                    {/* <span className='animate-pulse mx-auto'>Loading</span> */}
                  </td>
                </tr>
              )}

              {!isFetching &&
                newData &&
                newData.length !== 0 &&
                newData
                  ?.sort((a: any, b: any) => {
                    if (a.readable_start_datetime > b.readable_start_datetime) return -1
                    if (a.readable_start_datetime < b.readable_start_datetime) return 1
                    return 0
                  })
                  ?.slice(offset, offset + limit)
                  .map((row: any) => {
                    // const duration = moment.duration(row.end_datetime - row.start_datetime, 'seconds').humanize()

                    // const time = moment.unix(row.start_datetime).local().format('D.M.YYYY hh:mm A').split(' ')
                    const time = moment
                      .utc(row.readable_start_datetime)
                      .local()
                      .format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm A`)
                    return (
                      <tr>
                        <td>{row.device_name || '-'}</td>
                        <td>{calculateDuration(row.end_datetime, row.start_datetime)}</td>
                        <td>
                          {time}
                          {/* {time[0]}
                          <br />
                          {time[1] + ' ' + time[2]} */}
                        </td>
                      </tr>
                    )
                  })}
              {!isFetching && newData?.length === 0 && (
                <tr>
                  <td colSpan={3} style={{ textAlign: 'center', color: getColorCode('redToBlue') }}>
                    No Data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {!isFetching && newData && (
            <TablePagination
              sx={{ marginRight: 0 }}
              rowsPerPageOptions={[3, 6, 10, 20, 50]}
              component='div'
              count={newData?.length || 0}
              rowsPerPage={limit}
              page={currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      )}
    </div>
  )
}

export default TimeSuperVisionCommon
