import React from 'react'
import { getColorCode } from '../../../utils/helper'

const NotRegistered = () => {
  const color = getColorCode('lightblueToGray')

  return (
    <>
      <svg width='58' height='58' viewBox='0 0 58 58' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M43.5 56.2359H14.5C12.8525 56.2337 11.2732 55.5783 10.1083 54.4134C8.94333 53.2484 8.28791 51.6691 8.28571 50.0216V32.7597H3.45238C1.54943 32.7597 0 31.2103 0 29.3073C0 28.3462 0.408762 27.4237 1.12133 26.7774L27.5693 1.56952C27.9552 1.20353 28.4668 0.999512 28.9986 0.999512C29.5305 0.999512 30.042 1.20353 30.4279 1.56952L37.2857 8.0959V5.83114C37.2857 5.28176 37.504 4.75489 37.8924 4.36642C38.2809 3.97795 38.8078 3.75971 39.3571 3.75971H47.6429C48.1922 3.75971 48.7191 3.97795 49.1076 4.36642C49.496 4.75489 49.7143 5.28176 49.7143 5.83114V19.9389L56.9146 26.8106C57.5912 27.4237 58 28.3462 58 29.3073C58 31.2103 56.4506 32.7597 54.5476 32.7597H49.7143V50.0216C49.7143 53.4464 46.9275 56.2359 43.5 56.2359Z'
          fill={color}
        />
        <path
          d='M29 0.999512C28.4673 0.999051 27.955 1.2038 27.5693 1.57123L1.12133 26.7791C0.768712 27.0996 0.486848 27.4902 0.293764 27.9258C0.10068 28.3614 0.00062657 28.8325 0 29.309C0 31.212 1.54943 32.7614 3.45238 32.7614H8.28571V50.0233C8.28571 53.4481 11.0725 56.2376 14.5 56.2376H29V0.999512Z'
          fill='white'
          fillOpacity='0.12'
        />
        <path d='M24 45.9995H34V31.9995H24V45.9995Z' fill={color} />
        <path
          d='M40.2185 41.6001L32.8621 28.6893C31.8736 26.9521 30.5058 25.9995 29 25.9995C27.4942 25.9995 26.1264 26.9521 25.1379 28.6893L17.7815 41.6001C16.8505 43.2476 16.747 44.8278 17.4942 46.0718C18.2413 47.3159 19.7126 47.9995 21.6436 47.9995H36.3564C38.2874 47.9995 39.7587 47.3159 40.5058 46.0718C41.253 44.8278 41.1495 43.2364 40.2185 41.6001ZM28.1379 33.8446C28.1379 33.3851 28.5287 33.0041 29 33.0041C29.4713 33.0041 29.8621 33.3851 29.8621 33.8446V39.4483C29.8621 39.9078 29.4713 40.2889 29 40.2889C28.5287 40.2889 28.1379 39.9078 28.1379 39.4483V33.8446ZM29.8161 43.6062C29.7586 43.6511 29.7012 43.6959 29.6437 43.7407C29.5747 43.7856 29.5058 43.8192 29.4368 43.8416C29.3678 43.8752 29.2989 43.8976 29.2184 43.9088C29.1494 43.92 29.069 43.9312 29 43.9312C28.931 43.9312 28.8506 43.92 28.7701 43.9088C28.7011 43.8976 28.6322 43.8752 28.5632 43.8416C28.4942 43.8192 28.4253 43.7856 28.3563 43.7407C28.2988 43.6959 28.2414 43.6511 28.1839 43.6062C27.977 43.3933 27.8506 43.1019 27.8506 42.8105C27.8506 42.5191 27.977 42.2277 28.1839 42.0148C28.2414 41.97 28.2988 41.9251 28.3563 41.8803C28.4253 41.8355 28.4942 41.8019 28.5632 41.7794C28.6322 41.7458 28.7011 41.7234 28.7701 41.7122C28.9195 41.6786 29.0805 41.6786 29.2184 41.7122C29.2989 41.7234 29.3678 41.7458 29.4368 41.7794C29.5058 41.8019 29.5747 41.8355 29.6437 41.8803C29.7012 41.9251 29.7586 41.97 29.8161 42.0148C30.023 42.2277 30.1494 42.5191 30.1494 42.8105C30.1494 43.1019 30.023 43.3933 29.8161 43.6062Z'
          fill='white'
        />
      </svg>
    </>
  )
}

export default NotRegistered
