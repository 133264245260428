import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

interface PropsType {
  className: string
  body: JSX.Element
  styles: any
  testId:any
}

export default function CardComponent(props: PropsType) {
  return (
    <Card className={props.className} style={{ ...props.styles }}>
      <CardContent className='card_content' sx={{ height: '100%' }}>
        {props.body}
      </CardContent>
    </Card>
  )
}
