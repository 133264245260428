import { Box, Button, useTheme } from '@mui/material'
import { getColorCode } from '../../utils/helper'
import { Close } from '@mui/icons-material'
import { useModalReset } from '../../hook/device-control'

const Deprovision = ({ handleClose, deviceGwId, setSnackBarInfo, setCallDeviceDetail, deviceGwEmail }: any) => {
  const theme = useTheme()

  const resetMutation = useModalReset()

  const onSubmit = () => {
    const payload: any = {
      _gw_uid: deviceGwId
    }
    const url: any = 'webapp_dashboard_acc_remove_from_gw'

    resetMutation.mutate(
      { url, payload },
      {
        onSuccess: (data: any) => {
          setSnackBarInfo({
            isOpen: true,
            message: `User removed from the Gateway successfully`,
            type: 'Closed',
            severity: 'success'
          })
          setCallDeviceDetail(true)
          handleClose()
          return 'OK'
        },
        onError: (error: any) => {
          setSnackBarInfo({
            isOpen: true,
            message: error?.message,
            type: 'redToRed',
            severity: 'error'
          })
          return 'ERROR'
        }
      }
    )
  }

  return (
    <Box className='rounded'>
      <Box
        sx={{
          width: '500px',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box textAlign={'center'} display={'flex'} justifyContent={'space-between'} padding={2}>
          <h3 style={{ fontSize: '20px', fontWeight: '600', width: '100%' }}>Deprovision Device</h3>
          <Close
            className='cursor-pointer'
            onClick={() => {
              handleClose()
            }}
          />
        </Box>
        <Box className='pb-3 bg-[#f7f8fe] pt-1 text-center' paddingLeft={5} paddingRight={5}>
          <Box marginTop={2} display={'flex'} alignItems={'center'}>
            <label style={{ textWrap: 'nowrap', fontWeight: 'bold' }}>GW UID:&nbsp; </label>
            {deviceGwId}
          </Box>
          <Box marginTop={2} display={'flex'} alignItems={'center'}>
            <Box>
              <label style={{ textWrap: 'nowrap', fontWeight: 'bold' }}>Deprovision From:&nbsp;</label>
              {deviceGwEmail}
            </Box>
          </Box>
          <div className='mt-4 mb-3'>
            <Button
              type='submit'
              variant='contained'
              size='medium'
              sx={{
                marginRight: '10px',
                background: getColorCode('infoBlue'),
                '&:hover': {
                  backgroundColor: getColorCode('infoBlue')
                }
              }}
              onClick={onSubmit}
            >
              Deprovision Device
            </Button>
          </div>
        </Box>
      </Box>
    </Box>
  )
}
export default Deprovision
