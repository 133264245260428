import React from 'react'
import { getColorCode } from '../../utils/helper'

const RefreshBtn = ({ reCall }: any) => {
  return (
    <svg
      width='24'
      height='24'
      className={reCall ? 'mx-auto animate-spin' : 'mx-auto'}
      // className='mx-auto'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22 12.0001C22 17.5201 17.52 22.0001 12 22.0001C6.48 22.0001 3.11 16.4401 3.11 16.4401M3.11 16.4401H7.63M3.11 16.4401V21.4401M2 12.0001C2 6.48006 6.44 2.00006 12 2.00006C18.67 2.00006 22 7.56006 22 7.56006M22 7.56006V2.56006M22 7.56006H17.56'
        stroke={getColorCode('purpleBlue')}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default RefreshBtn
