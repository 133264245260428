import { DashboardCountState, DashboardCountAction } from './types'

const initialState: DashboardCountState = {
  data: [],
  loading: false,
  error: null,
  assignItem: [],
  showingProvisionModel: false,
  provisionSuccessMessage: ''
}

const dashboardCountReducer = (state = initialState, action: DashboardCountAction): DashboardCountState => {
  switch (action.type) {
    case 'GET_DASHBOARD_COUNT_REQUEST':
      return {
        ...state,
        loading: true,
        error: null
      }
    case 'GET_DASHBOARD_COUNT_SUCCESS':
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null
      }
    case 'SET_DASHBOARD_ASSIGN_ITEM_SUCCESS':
      const newAssignItem = [...state.assignItem, ...action.payload]
      const uniqueData = newAssignItem.filter(
        (obj, index, self) => index === self.findIndex(item => item.acc_id === obj.acc_id)
      )

      return {
        ...state,
        assignItem: uniqueData
      }
    case 'GET_DASHBOARD_COUNT_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case 'SET_PROVISION_EMAIL_RESET':
      return {
        ...state,
        showingProvisionModel: false,
        provisionSuccessMessage: '',
        error: ''
      }
    case 'SET_PROVISION_EMAIL_REQUEST':
      return {
        ...state,
        showingProvisionModel: true,
        provisionSuccessMessage: '',
        error: ''
      }
    case 'SET_PROVISION_EMAIL_SUCCESS':
      return {
        ...state,
        showingProvisionModel: false,
        provisionSuccessMessage: 'Provision account successfully',
        error: ''
      }
    case 'SET_PROVISION_EMAIL_FAILURE':
      return {
        ...state,
        showingProvisionModel: false,
        provisionSuccessMessage: '',
        error: action.payload
      }
    default:
      return state
  }
}

export default dashboardCountReducer
