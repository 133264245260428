import React, { useContext } from 'react'
import BreadcrumbsArrowRight from '../../../common-images/app-logo/ArrowRight'
import { BreadcrumbContext } from '../../../contexts/BreadcrumbContext'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSessionContex } from '../../../SessionContex'
type BreadcrumbsDataType = {
  label: any
  href: string
  onClick?: any
}

const Breadcrumbs = () => {
  const { breadcrumbData }: any = useContext(BreadcrumbContext)
  const { setServiceApi } = useSessionContex()
  const email = localStorage.getItem('email')

  const Navigate = useNavigate()
  const dispatch = useDispatch()
  return (
    <div className='breadcrumbs'>
      <div className='breadcrumbs-line' />
      <div className='breadcrumbs-options'>
        {breadcrumbData.map((data: BreadcrumbsDataType, i: number) => {
          return (
            <div
              className={`d-flex align-center
               ${i === breadcrumbData.length - 1 && !data.onClick && 'pointer-events-none cursor-auto'}`}
              key={i}
              onClick={e => {
                e.stopPropagation()
                Navigate(data.href)
                if (data.label === 'Home') {
                  dispatch({ type: 'SET_DEVICE_ACTIVE_TAB_REQUEST', payload: 0 })
                }

                if (data.label === email) {
                  dispatch({ type: 'SET_DEVICE_ACTIVE_TAB_REQUEST', payload: 0 })
                }

                if (i === breadcrumbData.length - 3) {
                  localStorage.removeItem('isPCverified')
                  setServiceApi(false)
                }
              }}
            >
              <p className='breadcrumbs-option'>{data.label}</p>{' '}
              {i <= breadcrumbData.length - 2 && <BreadcrumbsArrowRight />}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Breadcrumbs
