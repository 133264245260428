import { useEffect, useState } from 'react'
// import { Formik } from 'formik'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Box, Button, Grid, Theme, Tooltip, Typography, useTheme } from '@mui/material'

import { getColorCode } from '../../utils/helper'
import { useKeyClockAddMSO, updateMSO, useAddMSO, useSendMail } from '../../hook/useAllCustomers'
import { useSessionContex } from '../../SessionContex'
import { Close } from '@mui/icons-material'

const MsoAddEdit = ({
  edit,
  handleClose,
  editData,
  setSnackBarInfo,
  deviceOpenModal,
  setDeviceOpenModal,
  setReCall
}: any) => {
  const [title, setTitle] = useState('Add MSO')
  const theme = useTheme()

  const addMSOCustomer = useAddMSO()
  const addMSOKeyClock = useKeyClockAddMSO()
  const updateMutate = updateMSO()
  const sentMail = useSendMail()
  const { openModal, setOpenModal } = useSessionContex()

  const emailVal =
    /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i

  // .matches(/^(\+?[0-9-.]+)$/, 'Only numbers, "+" and "-" are allowed')
  const validationSchema = Yup.object().shape({
    customer_phone: Yup.string()
      .matches(
        // /^(\+?[0-9-+.]+|\(?\d{3}\)?[0-9-+.]+)$/,
        /^(\+?[0-9-+.]+|\(?\d{3}\)?-?[0-9-+.]+)$/,
        'Only numbers, "-" and "+" are allowed'
      )
      .test('is-first-char-plus', 'If "+" is used, it must be the first character', (value: any) => {
        const a = value && (value.indexOf('+') === 0 ? value.substring(1) : value)
        return a ? !a.includes('+') : true
      })
      .test('is-last-char-minus', 'If "-", it cannot be the first or last character', (value: any) => {
        return value ? value.indexOf('-') !== 0 && value.lastIndexOf('-') !== value.length - 1 : true
      })
      .max(15, 'Phone number cannot exceed 15 characters'),
    // .required('Please enter contact number')
    // .min(10, 'Contact number must be 10 characters')
    // .matches(phoneNumberRegex, 'Please enter valid value'),
    // customer_name: Yup.string().required('Please enter Name'),
    customer_email: Yup.string().email('Please enter a valid email').matches(emailVal, 'Invalid email'),
    // .required('Please enter contact email'),
    customer_address: Yup.string(),
    // .required('Please enter MSO address'),
    white_label: Yup.string().trim().required('A valid name is required to create a MSO'),
    customer_contact_person: Yup.string()
    // .required('Please enter  MSO contact person name')
  })

  const formik: any = useFormik({
    initialValues: {
      customer_name: editData?.customer_name || '',
      customer_email: editData?.customer_email || '',
      customer_phone: editData?.customer_phone || '',
      customer_address: editData?.customer_address || '',
      white_label: editData?.white_label || '',
      customer_contact_person: editData?.customer_contact_person || ''
    },
    onSubmit: (values: any, actions: any) => {
      actions.setSubmitting(true)
      if (edit) {
        const data = {
          values,
          id: editData?.id
        }
        updateMutate.mutate(data, {
          onSuccess: (data: any) => {
            setTimeout(() => {
              actions.setSubmitting(false)
            }, 200)
            if (data === 401) {
              !openModal && setOpenModal(true)
            }
            setSnackBarInfo({
              isOpen: true,
              message: 'MSO Edited Successfully.',
              type: 'Closed'
            })

            formik.resetForm()
            if (deviceOpenModal) {
              setDeviceOpenModal(false)
            }
            handleClose()
            setReCall(true)
          },
          onError: (error: any) => {
            actions.setSubmitting(false)
            if (error?.response?.status === 23505 || error?.response?.status === 409) {
              setSnackBarInfo({
                isOpen: true,
                message:
                  error.response.data.errorMessage ||
                  `The name given for the MSO ${values?.white_label} is already in use. Please enter a unique MSO name.`,
                type: 'redToRed'
              })
            } else {
              setSnackBarInfo({
                isOpen: true,
                message: `${error ? error : `Failed to ${edit ? 'update' : 'add'} MSO.`}`,
                type: 'redToRed'
              })
            }
          }
        })
      } else {
        const keyPayload = {
          name: values.white_label,
          attributes: {
            'custom:whitelabel': [values.white_label]
          }
        }
        addMSOKeyClock.mutate(keyPayload, {
          onSuccess: (data: any) => {
            addMSOCustomer.mutate(values)
            const mailPayload = {
              mso_name: values.white_label
            }
            sentMail.mutate(mailPayload, {
              onSuccess: (data: any) => {
                if (data !== 401) {
                  setSnackBarInfo({
                    isOpen: true,
                    message: 'MSO Added Successfully.',
                    type: 'Closed'
                  })
                }
                formik.resetForm()
                setReCall(true)
                handleClose()
                setTimeout(() => {
                  actions.setSubmitting(false)
                }, 200)
              },
              onError: (error: any) => {
                if (data !== 401) {
                  // setSnackBarInfo({
                  //   isOpen: true,
                  //   message: `${
                  //     error ? `MSO Added Successfully and ${error}` : `MSO Added Successfully and Failed to sent mail.`
                  //   }`,
                  //   type: 'Closed'
                  // })
                }

                formik.resetForm()
                setReCall(true)
                handleClose()
                setTimeout(() => {
                  actions.setSubmitting(false)
                }, 200)
              }
            })

            if (data === 401) {
              !openModal && setOpenModal(true)
            }
          },
          onError: (error: any) => {
            actions.setSubmitting(false)
            if (error?.response?.status === 23505 || error?.response?.status === 409) {
              setSnackBarInfo({
                isOpen: true,
                message:
                  error.response.data.errorMessage ||
                  `The name given for the MSO ${values?.white_label} is already in use. Please enter a unique MSO name.`,
                type: 'redToRed'
              })
            } else {
              //MSO Name field requires a unique entry and '[value]' has already been used
              setSnackBarInfo({
                isOpen: true,
                message: `${error ? error?.message : `Failed to ${edit ? 'update' : 'add'} MSO.`}`,
                type: 'redToRed'
              })
            }
          }
        })
      }
    },
    validationSchema
  })

  useEffect(() => {
    setTitle(edit ? 'Edit MSO Customer' : 'Add MSO Customer')
  }, [edit])

  useEffect(() => {
    formik.resetForm()
  }, [])

  const newStyle: React.CSSProperties = { color: getColorCode('redToBlue'), display: 'block', textAlign: 'left' }
  const newSpanStyle: React.CSSProperties = { textWrap: 'nowrap', fontWeight: 'bold' }

  return (
    <Box className='rounded'>
      <Box
        sx={{
          width: '700px',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box textAlign={'center'} display={'flex'} justifyContent={'space-between'} padding={2}>
          <h3 style={{ fontSize: '20px', fontWeight: '600', width: '100%' }}>{title}</h3>

          <Close
            className='cursor-pointer'
            onClick={() => {
              formik.resetForm()
              handleClose()
            }}
          />
        </Box>
        <Box className='pb-2 bg-[#f7f8fe]' paddingLeft={5} paddingRight={5}>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Box marginTop={2}>
              <Grid container spacing={2}>
                <Grid item margin='auto' md={4} xs={12} sm={12}>
                  <span style={newSpanStyle}>MSO Name: *</span>
                </Grid>
                <Grid item md={8} xs={12} sm={12}>
                  <input
                    type='text'
                    className='p-2 w-full border-[1px] verifypin-form-input'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.white_label}
                    name='white_label'
                    placeholder='Please enter MSO name'
                    style={
                      formik.errors.white_label
                        ? { border: `1px solid ${getColorCode('redToBlue')}` }
                        : { border: `1px solid #FFF` }
                    }
                    disabled={edit}
                    data-testid='testid_mso_name'
                  />
                  {formik.errors.white_label && (
                    <span className='errorText' style={newStyle}>
                      {formik.errors.white_label}
                    </span>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box marginTop={2}>
              <Grid container spacing={2}>
                <Grid item margin='auto' md={4} xs={12} sm={12}>
                  <span style={newSpanStyle}>MSO Address:</span>
                </Grid>
                <Grid item md={8} xs={12} sm={12}>
                  <textarea
                    className='p-2 w-full border-[1px] verifypin-form-input'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.customer_address}
                    name='customer_address'
                    placeholder='Please enter MSO address'
                    style={{
                      resize: 'none',
                      overflow: 'auto',
                      border: `${
                        formik.errors.customer_address ? `1px solid ${getColorCode('redToBlue')}` : `1px solid #FFF`
                      }`
                    }}
                    rows={3}
                    data-testid='testid_mso_address'
                  ></textarea>
                  {formik.errors.customer_address && (
                    <span className='errorText' style={newStyle}>
                      {formik.errors.customer_address}
                    </span>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box marginTop={2}>
              <Grid container spacing={2}>
                <Grid item margin='auto' md={4} xs={12} sm={12}>
                  <span style={newSpanStyle}>Contact Name:</span>
                </Grid>
                <Grid item md={8} xs={12} sm={12}>
                  <input
                    type='text'
                    className='p-2 w-full border-[1px] verifypin-form-input'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.customer_contact_person}
                    name='customer_contact_person'
                    placeholder='Please enter MSO contact person name'
                    style={
                      formik.errors.customer_contact_person
                        ? { border: `1px solid ${getColorCode('redToBlue')}` }
                        : { border: `1px solid #FFF` }
                    }
                    data-testid='testid_contact_name'
                  />
                  {formik.errors.customer_contact_person && (
                    <span className='errorText' style={newStyle}>
                      {formik.errors.customer_contact_person}
                    </span>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box marginTop={2}>
              <Grid container spacing={2}>
                <Grid item margin='auto' md={4} xs={12} sm={12}>
                  <span style={newSpanStyle}>Contact Mobile:</span>
                </Grid>
                <Grid item md={8} xs={12} sm={12}>
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: '#707e8c'
                        }
                      }
                    }}
                    title={
                      <Typography>
                        Allowed formats: 2124567890, +12124567890, +1-212-456-7890, 1-212-456-7890
                      </Typography>
                    }
                    placement='top'
                  >
                    <input
                      type='text'
                      className='p-2 w-full border-[1px] verifypin-form-input'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.customer_phone}
                      name='customer_phone'
                      placeholder='Please enter contact mobile'
                      style={
                        formik.errors.customer_phone
                          ? { border: `1px solid ${getColorCode('redToBlue')}` }
                          : { border: `1px solid #FFF` }
                      }
                      maxLength={15}
                      data-testid='testid_contact_mobile'
                    />
                  </Tooltip>
                  {formik.errors.customer_phone && (
                    <span className='errorText' style={newStyle}>
                      {formik.errors.customer_phone}
                    </span>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box marginTop={2}>
              <Grid container spacing={2}>
                <Grid item margin='auto' md={4} xs={12} sm={12}>
                  <span style={newSpanStyle}>Contact Email:</span>
                </Grid>
                <Grid item md={8} xs={12} sm={12}>
                  <input
                    type='email'
                    className='p-2 w-full border-[1px] verifypin-form-input'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.customer_email}
                    name='customer_email'
                    placeholder='Please enter contact email'
                    style={
                      formik.errors.customer_email
                        ? { border: `1px solid ${getColorCode('redToBlue')}` }
                        : { border: `1px solid #FFF` }
                    }
                    data-testid='testid_contact_email'
                  />
                  {formik.errors.customer_email && (
                    <span className='errorText' style={newStyle}>
                      {formik.errors.customer_email}
                    </span>
                  )}
                </Grid>
              </Grid>
            </Box>

            <div className='mt-4 mb-3 text-center'>
              <Button
                type='submit'
                variant='contained'
                size='medium'
                sx={{
                  marginRight: '10px',
                  background: getColorCode('infoBlue'),
                  '&:hover': {
                    backgroundColor: getColorCode('infoBlue')
                  }
                }}
                disabled={formik.isSubmitting}
              >
                {edit ? 'Update' : 'Add'}
              </Button>

              {/* <Button
                onClick={() => {
                  formik.resetForm()
                  handleClose()
                }}
                size='medium'
                variant='outlined'
                sx={{
                  color: getColorCode('infoBlue'),
                  borderColor: getColorCode('infoBlue')
                }}
              >
                Cancel
              </Button> */}
            </div>
          </form>
        </Box>
      </Box>
    </Box>
  )
}
export default MsoAddEdit
