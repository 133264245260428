import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../Breadcrumbs'
import RightSideBar from '../RightSideBar'
import HeaderLogo from '../../../common-images/app-logo/header-logo'
import HitronHeaderLogo from '../../../common-images/hitron-logo/header-logo'
import hitronHeaderImg from '../../../common-images/hitron-logo/small-logo.png'
import { useScreenWidth } from '../../../hook/useScreenWidth'
import SidebarCollapseLogo from '../../../common-images/app-logo/sidebar-collapes-logo'
import HitronSidebarCollapseLogo from '../../../common-images/hitron-logo/sidebar-collapes-hiton-logo'
import hitronSidebarCollapseNewLogo from '../../../common-images/hitron-logo/small-logo.png'
import { Box } from '@mui/material'

const HeaderSection = () => {
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth)
  const width = useScreenWidth()

  useEffect(() => {
    setScreenWidth(width)
  }, [width])

  return (
    <div className='headerWrapper'>
      {screenWidth > 1441 ? (
        <>
          {process.env.REACT_APP_MODE === 'local' && <HeaderLogo className={'headerWrapper-logo'} />}
          {process.env.REACT_APP_MODE === 'hitron' && process.env.REACT_APP_REALM !== 'Hitron' && (
            <HitronHeaderLogo className={'headerWrapper-logo'} />
          )}
          {process.env.REACT_APP_MODE === 'hitron' && process.env.REACT_APP_REALM === 'Hitron' && (
            <Box className={'headerWrapper-logo'}>
              <Box width={'50px'}>
                <img src={hitronHeaderImg} alt='' />
              </Box>
            </Box>
          )}
        </>
      ) : (
        <>
          {process.env.REACT_APP_MODE === 'local' && <SidebarCollapseLogo className={'headerWrapper-logo'} />}
          {process.env.REACT_APP_MODE === 'hitron' && process.env.REACT_APP_REALM !== 'Hitron' && (
            <HitronSidebarCollapseLogo className={'headerWrapper-logo'} />
          )}
          {process.env.REACT_APP_MODE === 'hitron' && process.env.REACT_APP_REALM === 'Hitron' && (
            <Box className={'headerWrapper-logo'}>
              <Box width={'50px'}>
                <img src={hitronSidebarCollapseNewLogo} alt='' />
              </Box>
            </Box>
          )}
        </>
      )}
      <div className='headerActions'>
        {screenWidth > 1300 ? <Breadcrumbs /> : <span></span>}
        <RightSideBar />
      </div>
    </div>
  )
}

export default HeaderSection
