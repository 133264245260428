import React from 'react'
import { getColorCode } from '../../../utils/helper'

const AllDevices = () => {
  const color = getColorCode('infoBlue')
  return (
    <>
      <svg width='58' height='58' viewBox='0 0 58 58' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_2141_8560)'>
          <path
            d='M43.5 56.2359H14.5C12.8525 56.2337 11.2732 55.5783 10.1083 54.4134C8.94333 53.2484 8.28791 51.6691 8.28571 50.0216V32.7597H3.45238C1.54943 32.7597 0 31.2103 0 29.3073C0 28.3462 0.408762 27.4237 1.12133 26.7774L27.5693 1.56952C27.9552 1.20353 28.4668 0.999512 28.9986 0.999512C29.5305 0.999512 30.042 1.20353 30.4279 1.56952L37.2857 8.0959V5.83114C37.2857 5.28176 37.504 4.75489 37.8924 4.36642C38.2809 3.97795 38.8078 3.75971 39.3571 3.75971H47.6429C48.1922 3.75971 48.7191 3.97795 49.1076 4.36642C49.496 4.75489 49.7143 5.28176 49.7143 5.83114V19.9389L56.9146 26.8106C57.5912 27.4237 58 28.3462 58 29.3073C58 31.2103 56.4506 32.7597 54.5476 32.7597H49.7143V50.0216C49.7143 53.4464 46.9275 56.2359 43.5 56.2359Z'
            fill={color}
          />
          <path
            d='M29 0.999512C28.4673 0.999051 27.955 1.2038 27.5693 1.57123L1.12133 26.7791C0.768712 27.0996 0.486848 27.4902 0.293764 27.9258C0.10068 28.3614 0.00062657 28.8325 0 29.309C0 31.212 1.54943 32.7614 3.45238 32.7614H8.28571V50.0233C8.28571 53.4481 11.0725 56.2376 14.5 56.2376H29V0.999512Z'
            fill='white'
            fillOpacity='0.12'
          />
          <path
            d='M29 24.7134C22.3834 24.7134 17 30.0968 17 36.7134C17 43.33 22.3834 48.7134 29 48.7134C35.6166 48.7134 41 43.33 41 36.7134C41 30.0968 35.6166 24.7134 29 24.7134Z'
            fill='white'
            stroke='white'
            strokeWidth='1.4'
          />
          <path
            d='M24.6637 37.4058L24.6637 37.4058L24.6648 37.405C27.2973 35.3735 30.695 35.3734 33.3276 37.4046C33.4483 37.4993 33.5878 37.5429 33.7265 37.5429C33.9203 37.5429 34.1152 37.459 34.234 37.2885C34.455 37.0037 34.4035 36.5976 34.1177 36.3806C31.0103 33.9724 26.9818 33.9727 23.868 36.381L23.868 36.381L23.8674 36.3814C23.5884 36.5994 23.5383 37.006 23.7536 37.2902L23.7535 37.2902L23.7549 37.292C23.973 37.5711 24.3795 37.6211 24.6637 37.4058Z'
            fill={color}
            stroke={color}
            strokeWidth='0.3'
          />
          <path
            d='M22.7306 35.0858L22.7306 35.0858L22.7318 35.0849C26.5375 32.1402 31.4552 32.14 35.2678 35.0846C35.3885 35.1793 35.528 35.2229 35.6667 35.2229C35.8548 35.2229 36.0545 35.1385 36.1793 34.9706C36.4022 34.6856 36.351 34.278 36.0645 34.0605C31.7708 30.746 26.2227 30.7459 21.9355 34.0605C21.6496 34.2776 21.5964 34.6933 21.8227 34.9731C22.0409 35.2512 22.4468 35.3008 22.7306 35.0858Z'
            fill={color}
            stroke={color}
            strokeWidth='0.3'
          />
          <path
            d='M25.924 39.8393L25.924 39.8393L25.925 39.8385C27.7976 38.3935 30.2087 38.3936 32.0745 39.838C32.1952 39.9327 32.3346 39.9764 32.4734 39.9764C32.6637 39.9764 32.8558 39.8903 32.9794 39.724C33.2022 39.439 33.151 39.0315 32.8646 38.8139C30.5173 36.9992 27.4755 36.9994 25.1283 38.8144L25.1283 38.8144L25.1277 38.8148C24.8486 39.0329 24.7985 39.4395 25.0138 39.7236L25.0138 39.7236L25.0152 39.7254C25.2332 40.0045 25.6398 40.0545 25.924 39.8393Z'
            fill={color}
            stroke={color}
            strokeWidth='0.3'
          />
          <path
            d='M27.6574 42.2791L27.6574 42.2791L27.6588 42.278C28.4712 41.6468 29.522 41.6467 30.3344 42.2777C30.4552 42.3725 30.5947 42.4162 30.7334 42.4162C30.9318 42.4162 31.1236 42.3223 31.2399 42.1632C31.4622 41.8783 31.4109 41.4712 31.1247 41.2538C29.8307 40.2524 28.1555 40.2525 26.8616 41.2542L26.8616 41.2542L26.8611 41.2546C26.582 41.4727 26.5319 41.8793 26.7472 42.1634L26.7472 42.1634L26.7486 42.1652C26.9666 42.4443 27.3732 42.4943 27.6574 42.2791Z'
            fill={color}
            stroke={color}
            strokeWidth='0.3'
          />
        </g>
        <defs>
          <clipPath id='clip0_2141_8560'>
            <rect width='58' height='58' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}

export default AllDevices
