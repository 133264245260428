import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import dashboardCountReducer from './dashboard/reducers'
import globalSearchReducer from './GlobalSearch/reducer'
import customerStatisticsReducer from './customerStatistics/reducer'
import deviceStatisticsReducer from './deviceStatistics/reducer'
import storage from 'redux-persist/lib/storage' // default: localStorage
import { persistStore, persistReducer } from 'redux-persist'
import networkHealthMapReducer from './networkHealthMap/reducer'
import dashboardProblemsReducer from './dashboardProblems/reducers'
import deviceInformationReducer from './deviceInformation/reducers'

const middleWares = [thunk]

const persistConfig = {
  key: 'root', // key for the root of the state object
  storage // storage engine (e.g., localStorage)
}

const rootReducer = combineReducers({
  dashboardCount: dashboardCountReducer,
  dashboardProblems: dashboardProblemsReducer,
  deviceInformation: deviceInformationReducer,
  globalSearch: globalSearchReducer,
  deviceStatistics: deviceStatisticsReducer,
  customerStatistics: customerStatisticsReducer,
  networkHealthMap: networkHealthMapReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer, applyMiddleware(...middleWares))

const persistor = persistStore(store)

export { store, persistor }
