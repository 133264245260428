import { ArrowLeft2, ArrowRight2 } from 'iconsax-react'
import React, { useEffect, useState } from 'react'
import { generateArray, generateSubArrays } from '../../utils/helper'

const Pagination = ({ current, onePage, totalPages, handleSetCurrentCount }: any) => {
  const [start, setStart] = useState(1)
  const [totalCount, setCount] = useState(1)
  const [end, setEnd] = useState(onePage)
  const [currentPage, setCurrentPage] = useState(current || 1)
  const page = generateArray(totalCount)

  const listOfPages = generateSubArrays(1, totalPages, onePage)

  useEffect(() => {
    setCount(Math.ceil(totalPages / onePage))
  }, [totalPages])

  useEffect(() => {
    handleSetCurrentCount(currentPage)
    if (listOfPages.length > 0) {
      setStart(listOfPages[currentPage - 1][0])
      setEnd(listOfPages[currentPage - 1][1])
    }
  }, [currentPage])

  const findSubarrayContainingElement = (page: any, element: any) => {
    return page.find((subarray: any) => subarray.includes(element))
  }

  const handleChangeCurrent = (e: number) => {
    setCurrentPage(e)
  }
  const preChange = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }
  const nextChange = () => {
    if (totalCount > currentPage) {
      setCurrentPage(currentPage + 1)
    }
  }

  const pages = findSubarrayContainingElement(page, currentPage)
  return (
    <div className='pagination-wrapper'>
      <div className='left-side'>
        <p>{`Showing ${start} to ${end} of ${totalPages} entries`}</p>
      </div>
      <div className='right-side'>
        <ArrowLeft2 size='26' color='#90969d' className='left-arrow' onClick={preChange} />
        <div className='page-count'>
          {pages &&
            pages.length > 0 &&
            pages.map((i: any, key: any) => {
              return currentPage === i ? (
                <span className='active-page' onClick={() => handleChangeCurrent(i)} key={key}>
                  {i}
                </span>
              ) : (
                <span className='disable-page' onClick={() => handleChangeCurrent(i)} key={key}>
                  {i}
                </span>
              )
            })}
        </div>
        <ArrowRight2 size='26' color='#90969d' className='right-arrow' onClick={nextChange} />
      </div>
    </div>
  )
}

export default Pagination
