import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import axios from 'axios'
import { KEYCLOAK_CONFIG, apiUrl, readOnlyApiUrl } from '../../app-config'
import { CustomersRootState } from './types'
import { notifyError } from '../../components/Toaster'
import xcpemSec from '../../axiosInstances/xcpemSec'
// import useAxiosAuth from 'src/@core/hooks/useAxiosAuth'

interface RangeFilterProps {
  limit: number
  offset: number
}

export const getCustomerStatistics = ({ limit, offset }: any): any => {
  return async (dispatch: any) => {
    const access_token = localStorage.getItem('access_token')

    const basUrl = `/webapp_white_labels_list?limit=${limit}&offset=${offset}`
    try {
      dispatch({ type: 'GET_CUSTOMER_STATISTICS_REQUEST' }) // Set loading to true
      const response = await xcpemSec.get(`${basUrl}`)

      const deviceStatistics = response.data
      dispatch({ type: 'GET_CUSTOMER_STATISTICS_SUCCESS', payload: deviceStatistics })
    } catch (error: any) {
      if (error?.response?.status === 401) {
        // localStorage.clear()
        // localStorage.clear()
        // window.location.href = '/#/login'
        // localStorage.setItem('error', 'true')

        return error?.response?.status
      } else {
        notifyError(`Customer Statistics: ${error?.response?.data?.message || error?.message}`)
        dispatch({ type: 'GET_CUSTOMER_STATISTICS_FAILURE', error: error.message, payload: [] })
      }
    }
  }
}

export const getCustomerStatisticsCount = (): any => {
  return async (dispatch: any) => {
    const access_token = localStorage.getItem('access_token')

    const basUrl = `/webapp_white_labels_list`
    try {
      dispatch({ type: 'GET_CUSTOMER_STATISTICS_COUNT_REQUEST' }) // Set loading to false
      const response = await xcpemSec.get(`${basUrl}`)
      const deviceStatistics = response.data

      dispatch({ type: 'GET_CUSTOMER_STATISTICS_COUNT_SUCCESS', payload: deviceStatistics })
    } catch (error: any) {
      if (error?.response?.status === 401) {
        // localStorage.clear()
        // localStorage.clear()
        // window.location.href = '/#/login'
        // localStorage.setItem('error', 'true')

        return error?.response?.status
      } else {
        notifyError(`Customer Statistics Count: ${error?.response?.data?.message || error?.message}`)
        dispatch({ type: 'GET_CUSTOMER_STATISTICS_FAILURE', error: error.message })
      }
    }
  }
}
