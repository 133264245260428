import Box from '@mui/material/Box'
// @ts-ignore
import styles from './styles.module.scss'
import { getColorCode } from '../../../../utils/helper'
import RadialBarChart from '../../radial-progress-bar/radial-progress-bar'
import { FC } from 'react'
import { ArrowSquareUp } from 'iconsax-react'

interface SecurityScoreCommonProps {
  title: string
  score: number
  score_suggestions: string[]
}

const SecurityScoreCommon: FC<SecurityScoreCommonProps> = ({ title, score_suggestions, score }: any) => {
  const criticalColorCode = getColorCode('redToBlue')
  // console.log('=====>>@@##', score_suggestions)
  return (
    <Box className={styles.security_score}>
      <p className={styles.title}>{title}</p>

      <div className={styles.progress_bar}>
        <RadialBarChart score={score} />
      </div>
      {score === 5 ? (
        <>
          <div className={styles.alert_row} style={{ borderLeft: `4px solid ${getColorCode('Good')}` }}>
            <ArrowSquareUp color={getColorCode('Good')} size={24} />
            <span className='mr-auto ml-2' style={{ marginRight: 'auto', marginLeft: '8px' }}>
              Your network is all protected and everything is updated!
            </span>
            <span className={styles.score} style={{ color: getColorCode('Good') }}>
              {score}
            </span>
          </div>
        </>
      ) : (
        score_suggestions &&
        // Object.keys(score_suggestions) &&
        score_suggestions
          // [Object.keys(score_suggestions)[0]]
          ?.map((item: any, index: number) => {
            let message = ''
            let score = -1.0

            if (item.text === 'Please change the default Pincode of Parental Control') {
              message = 'Change Default PIN in Parental Control'
            } else if (item.text === 'Please change default Router Panel Password') {
              message = 'Change default Router Panel Password'
            } else if (item.text === 'The current WiFi connection password is weak, please change it') {
              message = 'The current WiFi connection password is weak, please change it '
              score = -0.5
            } else {
              message = item.text
            }

            return (
              <div
                className={styles.alert_row}
                key={`score_${index}`}
                style={{ borderLeft: `4px solid ${getColorCode('redToBlue')}` }}
              >
                <span>
                  {' '}
                  <ArrowSquareUp color={criticalColorCode} size={24} />
                </span>
                <span className='mr-auto ml-2' style={{ marginRight: 'auto', marginLeft: '8px' }}>
                  {message}
                </span>
                {/* <span className={styles.score} style={{ color: getColorCode('redToBlue') }}>
                {score}
              </span> */}
              </div>
            )
          })
      )}
      {/* {score_suggestions?.map((item: any, index: number) => {
        let message = ''
        let score = -1.0

        if (item === 'Please change the default Pincode of Parental Control') {
          message = 'Change Default PIN in Parental Control'
        } else if (item === 'Please change default Router Panel Password') {
          message = 'Change default Router Panel Password'
        } else if (item === 'The current WiFi connection password is weak, please change it') {
          message = 'Weak Router Password. '
          score = -0.5
        } else {
          message = item
        }

        return (
          <div
            className={styles.alert_row}
            key={`score_${index}`}
            style={{ borderLeft: `4px solid ${getColorCode('Critical')}` }}
          >
            <ArrowSquareUp color={criticalColorCode} size={24} />
            <span className='mr-auto ml-2' style={{ marginRight: 'auto', marginLeft: '8px' }}>
              {message}
            </span>
            <span className={styles.score} style={{ color: getColorCode('Critical') }}>
              {score}
            </span>
          </div>
        )
      })} */}
    </Box>
  )
}

export default SecurityScoreCommon
