import moment from 'moment/moment'
import WebSuperVisionTile from './web-supervision-tile'
import { useWebMonitoring } from '../../../../hook/parental-control/useParental'
import { currentISOTime, getColorCode } from '../../../../utils/helper'
import { FC, useEffect, useState } from 'react'
import { Box, FormControl, InputLabel, MenuItem } from '@mui/material'
// import {
//   DropdownMenu,
//   DropdownMenuContent,
//   DropdownMenuTrigger,
//   DropdownMenuItem
// } from '../../../Layouts/AnimatedLayout/DropDown/dropdown-menu'
// import DownSideArrow from '../../../common-images/app-logo/downside-arrow'
import { useSessionContex } from '../../../../SessionContex'
import Select from '@mui/material/Select'
interface Props {
  email: string
  isCard?: boolean
}

const WebSupervisionCommon: FC<Props> = ({ isCard, email }) => {
  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()

  const [filterLabel, setFilterLabel] = useState<string>('today')
  const [fromDate, setFromDate] = useState<any>(
    moment.utc(moment.tz(moment().startOf('day'), timeZone)).format('YYYYMMDDTHH:mm:ss')
  )

  const { data, isLoading, isFetching, refetch, error } = useWebMonitoring(fromDate, email)
  useEffect(() => {
    refetch()
  }, [])
  useEffect(() => {
    if (reCall) {
      refetch()
    }
  }, [reCall])

  useEffect(() => {
    if (!isFetching) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [isFetching])

  useEffect(() => {
    refetch()
  }, [fromDate])
  useEffect(() => {
    if (data === 401) {
      !openModal && setOpenModal(true)
    }
  }, [data])
  const sum = data?.results?.web_chart_items?.reduce((accumulator: any, item: any) => accumulator + item.count, 0) || 1
  const CartData = [
    {
      name: 'Today',
      value: 'today'
    },
    {
      name: 'Last 7 Days',
      value: 'thisWeek'
    },
    {
      name: 'Last 30 Days',
      value: 'thisMonth'
    }
  ]
  const handleFilterChange = (e: any) => {
    if (e.target.value === 'thisMonth') {
      setFromDate(moment.utc(moment.tz(moment().subtract(1, 'month'), timeZone)).format('YYYYMMDDTHH:mm:ss'))
    } else if (e.target.value === 'thisWeek') {
      setFromDate(moment.utc(moment.tz(moment().subtract(7, 'days'), timeZone)).format('YYYYMMDDTHH:mm:ss'))
    } else if (e.target.value === 'today') {
      setFromDate(moment.utc(moment.tz(moment().startOf('day'), timeZone)).format('YYYYMMDDTHH:mm:ss'))
    }
    setFilterLabel(e.target.value)
  }

  return (
    <div className='web-supervision-wrapper'>
      {isCard ? (
        <div className='inline-flex w-full justify-between items-center time-supervision-inner-wrapper mb-2'>
          <span className='text-[24px] font-sf-bold time-supervision-title'>Web Supervision</span>
          <div className='w-40 mt-2'>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>Filter</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={filterLabel}
                label='Filter'
                onChange={handleFilterChange}
                className='text-uppercase'
              >
                {CartData.map((option: any, index: number) => {
                  return (
                    <MenuItem value={option.value} className='text-uppercase' key={`option_${index}_${option}`}>
                      {option.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>
          {/* <DropdownMenu>
            <DropdownMenuTrigger className='dropdown-btn flex'>
              {filterLabel}
              <span className='down-arrow'>
                <DownSideArrow />
              </span>
            </DropdownMenuTrigger>
            <DropdownMenuContent className='drop-down'>
              {CartData.map((option: any, index: number) => {
                return (
                  <DropdownMenuItem
                    className='drop-down-option'
                    key={`option_${index}_${option}`}
                    onClick={(e: any) => handleFilterChange(option)}
                  >
                    {option.name}
                  </DropdownMenuItem>
                )
              })}
            </DropdownMenuContent>
          </DropdownMenu> */}
        </div>
      ) : (
        <h1 className='mb-[26px] font-sf-bold'>Web Supervision</h1>
      )}
      <div className='overflow-x-auto pb-3 web-supervision-inner-wrapper'>
        {/* {!isFetching &&
          data &&
          data?.results?.web_chart_items?.length !== 0 &&
          data.results?.web_chart_items
            ?.sort((a: any, b: any) => b.count - a.count)
            .map((item: any, index: number) => {
              return (
                <WebSuperVisionTile
                  key={`supervision_tile_${index}`}
                  value={item.count}
                  percentage={Math.ceil((item.count / sum) * 100)}
                  title={item.title}
                />
              )
            })} */}
        {/* {!isFetching && (data?.results?.web_chart_items?.length === 0 || error !== null) ? (
          <Box
            color={getColorCode('redToBlue')}
            display={'flex'}
            justifyContent={'center'}
            textAlign={'center'}
            margin={'auto'}
          >
            Web chart items and count not found
          </Box>
        ) : (
          data &&
          data?.results?.web_chart_items?.length !== 0 &&
          data.results?.web_chart_items
            ?.sort((a: any, b: any) => b.count - a.count)
            .map((item: any, index: number) => {
              return (
                <WebSuperVisionTile
                  key={`supervision_tile_${index}`}
                  value={item.count}
                  percentage={Math.ceil((item.count / sum) * 100)}
                  title={item.title}
                />
              )
            })
        )} */}

        {isFetching ? (
          <>
            {Array.from({ length: 8 }, (_, index) => {
              return (
                <WebSuperVisionTile
                  key={`supervision_loading_tile_${index}`}
                  value={'-' as unknown as number}
                  percentage={100}
                  title={'-'}
                  animate={true}
                />
              )
            })}
          </>
        ) : data && data?.results?.web_chart_items?.length !== 0 ? (
          data.results?.web_chart_items
            ?.sort((a: any, b: any) => b.count - a.count)
            .map((item: any, index: number) => {
              return (
                <WebSuperVisionTile
                  key={`supervision_tile_${index}`}
                  value={item.count}
                  percentage={Math.ceil((item.count / sum) * 100)}
                  title={item.title}
                />
              )
            })
        ) : (
          <Box
            color={getColorCode('redToBlue')}
            display={'flex'}
            justifyContent={'center'}
            textAlign={'center'}
            margin={'auto'}
          >
            Web chart items and count not found
          </Box>
        )}
      </div>
    </div>
  )
}

export default WebSupervisionCommon
