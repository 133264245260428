import { createTheme } from '@mui/material/styles'

// Define your custom breakpoints
const customBreakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1720,
    custom: 2200 // Add your custom breakpoint here
  }
}

const theme = createTheme({
  breakpoints: customBreakpoints
  // Other theme configurations...
})

export default theme
