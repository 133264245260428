import React from "react";

interface NetWorkSpeedProps {
  width?: string;
  height?: string;
}

const NetWorkSpeed: React.FC<NetWorkSpeedProps> = ({ width, height}) => {
  return (
    <svg  width={width ? width : '21px'} height={height ? height : '17px'} viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.0337 14.2406C16.0662 14.2406 18.5333 11.7735 18.5333 8.74093C18.5333 5.70839 16.0662 3.24121 13.0337 3.24121C10.0011 3.24121 7.53394 5.70835 7.53394 8.74093C7.53394 11.7735 10.0011 14.2406 13.0337 14.2406ZM8.93878 8.44627H8.13226C8.19963 7.31381 8.65244 6.28357 9.36115 5.48506L9.92981 6.05373C9.98733 6.11128 10.0627 6.14003 10.1381 6.14003C10.2135 6.14003 10.2889 6.11128 10.3465 6.05373C10.4615 5.93867 10.4615 5.75211 10.3465 5.63709L9.77779 5.06843C10.5763 4.35972 11.6065 3.9069 12.739 3.83953V4.64606C12.739 4.80877 12.8709 4.94068 13.0336 4.94068C13.1964 4.94068 13.3282 4.80877 13.3282 4.64606V3.83953C14.4607 3.9069 15.4909 4.35972 16.2894 5.06843L15.7207 5.63709C15.6057 5.75215 15.6057 5.93871 15.7207 6.05373C15.7783 6.11124 15.8537 6.14003 15.9291 6.14003C16.0045 6.14003 16.0799 6.11128 16.1375 6.05373L16.7061 5.48506C17.4147 6.28357 17.8676 7.31377 17.9349 8.44623H17.1284C16.9657 8.44623 16.8338 8.57814 16.8338 8.74085C16.8338 8.90356 16.9657 9.03548 17.1284 9.03548H17.9349C17.8676 10.1679 17.4147 11.1982 16.706 11.9966L16.1374 11.428C16.0223 11.313 15.8358 11.313 15.7207 11.428C15.6057 11.5431 15.6057 11.7296 15.7207 11.8447L16.2894 12.4133C15.4909 13.122 14.4607 13.5748 13.3282 13.6422V12.8357C13.3282 12.673 13.1964 12.5411 13.0336 12.5411C12.8709 12.5411 12.739 12.673 12.739 12.8357V13.6422C11.6065 13.5748 10.5763 13.122 9.77779 12.4133L10.3465 11.8447C10.4615 11.7296 10.4615 11.5431 10.3465 11.4281C10.2314 11.313 10.0448 11.313 9.92981 11.4281L9.36115 11.9967C8.65244 11.1982 8.19963 10.168 8.13226 9.03548H8.93878C9.10149 9.03548 9.2334 8.90356 9.2334 8.74085C9.2334 8.57818 9.10149 8.44627 8.93878 8.44627Z" fill="black"/>
      <path d="M16.606 14.4114C15.5381 15.0856 14.3028 15.442 13.0336 15.442C9.33855 15.442 6.33241 12.4358 6.33241 8.74078C6.33241 7.24484 6.81442 5.82994 7.72626 4.64901C7.82568 4.52024 7.80192 4.33521 7.67311 4.23575C7.54434 4.13632 7.35931 4.16009 7.25985 4.2889C6.26759 5.57389 5.74316 7.11332 5.74316 8.74078C5.74316 12.7607 9.01364 16.0312 13.0336 16.0312C14.4143 16.0312 15.7584 15.6434 16.9206 14.9096C17.0582 14.8228 17.0993 14.6408 17.0124 14.5032C16.9255 14.3657 16.7436 14.3246 16.606 14.4114Z" fill="black"/>
      <path d="M14.8106 1.66977V0.552949C14.8106 0.248034 14.5625 0 14.2576 0H11.8098C11.5048 0 11.2568 0.248034 11.2568 0.552949V1.66946C10.0852 1.96298 8.99818 2.54531 8.09345 3.37937C7.97384 3.48968 7.96625 3.67604 8.07652 3.7957C8.18683 3.91531 8.37323 3.92293 8.49284 3.81263C9.73305 2.66929 11.3457 2.03962 13.0337 2.03962C16.7288 2.03962 19.7349 5.04576 19.7349 8.74082C19.7349 10.6374 18.9258 12.4535 17.515 13.7232C17.3941 13.8321 17.3843 14.0183 17.4931 14.1393C17.5513 14.204 17.6316 14.2368 17.7122 14.2368C17.7824 14.2368 17.8529 14.2119 17.9092 14.1612C19.4439 12.7799 20.3241 10.8042 20.3241 8.74078C20.3242 5.33359 17.9745 2.46517 14.8106 1.66977ZM11.8461 1.54705V0.589247H14.2214V1.54764C13.8348 1.484 13.4381 1.45037 13.0338 1.45037C12.6328 1.45037 12.2358 1.48306 11.8461 1.54705Z" fill="black"/>
      <path d="M13.0334 8.94707C13.144 8.94707 13.2401 8.88615 13.2905 8.79611L14.3069 7.77978C14.4219 7.66472 14.4219 7.47816 14.3069 7.3631C14.1918 7.24804 14.0053 7.24804 13.8902 7.3631L13.328 7.9252V6.49042C13.328 6.32771 13.1961 6.1958 13.0334 6.1958C12.8706 6.1958 12.7388 6.32771 12.7388 6.49042V8.65237C12.7388 8.81516 12.8707 8.94707 13.0334 8.94707Z" fill="black"/>
      <path d="M4.67066 8.80732C4.67066 8.64461 4.53875 8.5127 4.37604 8.5127H0.617622C0.454911 8.5127 0.322998 8.64461 0.322998 8.80732C0.322998 8.97003 0.454911 9.10194 0.617622 9.10194H4.37604C4.53875 9.10194 4.67066 8.97007 4.67066 8.80732Z" fill="black"/>
      <path d="M1.79267 7.38612H4.76884C4.93155 7.38612 5.06346 7.25421 5.06346 7.0915C5.06346 6.92879 4.93155 6.79688 4.76884 6.79688H1.79267C1.62996 6.79688 1.49805 6.92879 1.49805 7.0915C1.49805 7.25421 1.62992 7.38612 1.79267 7.38612Z" fill="black"/>
      <path d="M5.16167 5.08105H2.72089C2.55818 5.08105 2.42627 5.21297 2.42627 5.37568C2.42627 5.53839 2.55818 5.6703 2.72089 5.6703H5.16167C5.32438 5.6703 5.4563 5.53839 5.4563 5.37568C5.4563 5.21293 5.32438 5.08105 5.16167 5.08105Z" fill="black"/>
      <path d="M5.06343 10.3903C5.06343 10.2276 4.93151 10.0957 4.7688 10.0957H1.79267C1.62996 10.0957 1.49805 10.2276 1.49805 10.3903C1.49805 10.553 1.62996 10.685 1.79267 10.685H4.76884C4.93151 10.685 5.06343 10.553 5.06343 10.3903Z" fill="black"/>
      <path d="M5.16167 11.811H2.72089C2.55818 11.811 2.42627 11.9429 2.42627 12.1057C2.42627 12.2684 2.55818 12.4003 2.72089 12.4003H5.16167C5.32438 12.4003 5.4563 12.2684 5.4563 12.1057C5.4563 11.9429 5.32438 11.811 5.16167 11.811Z" fill="black"/>
    </svg>

  );
};

export default NetWorkSpeed;
