import React from 'react'

const OverviewImage = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_59_279)">
      <path fillRule="evenodd" clipRule="evenodd" d="M4 5.96153C4 4.87821 4.87821 4 5.96153 4H19.0384C20.1218 4 21 4.87822 21 5.96153V12.5C21 12.8611 20.7072 13.1538 20.3461 13.1538C19.985 13.1538 19.6923 12.8611 19.6923 12.5V5.96153C19.6923 5.60042 19.3995 5.30769 19.0384 5.30769H5.96153C5.60043 5.30769 5.30769 5.60043 5.30769 5.96153V19.0384C5.30769 19.3995 5.60042 19.6923 5.96153 19.6923H12.5C12.8611 19.6923 13.1538 19.985 13.1538 20.3461C13.1538 20.7072 12.8611 21 12.5 21H5.96153C4.87822 21 4 20.1218 4 19.0384V5.96153Z" fill="#636363"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.9872 14.0255C14.904 14.0255 14.0257 14.9037 14.0257 15.987C14.0257 17.0703 14.904 17.9485 15.9872 17.9485C17.0705 17.9485 17.9488 17.0703 17.9488 15.987C17.9488 14.9037 17.0705 14.0255 15.9872 14.0255ZM12.718 15.987C12.718 14.1814 14.1817 12.7178 15.9872 12.7178C17.7928 12.7178 19.2565 14.1814 19.2565 15.987C19.2565 17.7926 17.7928 19.2562 15.9872 19.2562C14.1817 19.2562 12.718 17.7926 12.718 15.987Z" fill="#636363"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.656 17.3221C17.8815 17.0401 18.293 16.9944 18.575 17.2199L20.7545 18.9635C21.0365 19.1891 21.0822 19.6006 20.8566 19.8826C20.631 20.1646 20.2196 20.2103 19.9376 19.9846L17.7581 18.2411C17.4762 18.0155 17.4304 17.604 17.656 17.3221Z" fill="#636363"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.4873 9.01273C7.4873 8.65163 7.78004 8.35889 8.14115 8.35889H16.8591C17.2202 8.35889 17.5129 8.65163 17.5129 9.01273C17.5129 9.37384 17.2202 9.66658 16.8591 9.66658H8.14115C7.78004 9.66658 7.4873 9.37384 7.4873 9.01273Z" fill="#636363"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.4873 12.5C7.4873 12.1389 7.78004 11.8462 8.14115 11.8462H11.6283C11.9894 11.8462 12.2822 12.1389 12.2822 12.5C12.2822 12.8611 11.9894 13.1539 11.6283 13.1539H8.14115C7.78004 13.1539 7.4873 12.8611 7.4873 12.5Z" fill="#636363"/>
      </g>
      <defs>
      <clipPath id="clip0_59_279">
      <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
      </defs>
    </svg>
  )
}
export default OverviewImage
