import { useState } from 'react'
import { Modal } from 'antd'
import { Button, FormHelperText, IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import { Close } from '@mui/icons-material'
import { Formik } from 'formik'
import { Eye, EyeSlash } from 'iconsax-react'
import * as Yup from 'yup'
import { getColorCode } from '../../../utils/helper'
// import './index.scss'

const ResetWifiPassword = ({ isOpen, onClose, onSubmit, minValue, title, subTitle, testId }: any) => {
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }
  
  const resetPasswordSchema = Yup.object().shape(
    // minValue === 2
    //   ? {
    // password: Yup.string()
    //   .required('Please enter password')
    //   .min(2, 'The admin password should be minimum 2 characters long')
    //   .matches(/^\S*$/, 'Spaces are not allowed')
    // .matches(
    //   /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{2,}$/,
    //   'Password must contain at least 8 characters, one uppercase, one number and one special case character'
    // )
    //   }
    // :
    {
      password: Yup.string()
        .required('Please enter password')
        .min(8, `Password should be minimum 8 characters long`)
        .matches(/^\S*$/, 'Spaces are not allowed')
      // .matches(
      //   /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{2,}$/,
      //   'Password must contain at least 8 characters, one uppercase, one number and one special case character'
      // )
    }
  )
  const handleValidSubmit = (values: any, actions: any) => {
    actions.setSubmitting(false)
    onSubmit(values)
  }
  return (
    <Modal
      open={isOpen}
      footer={null}
      closable={false}
      width={500}
      wrapClassName={'reset_wifi_wrapper'}
      centered={true}
    >
      <Formik
        initialValues={{
          password: ''
        }}
        validationSchema={resetPasswordSchema}
        onSubmit={handleValidSubmit}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, resetForm }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className='reset_wifi_body_wrapper device-tab'>
              <div className='text-start d-flex justify-content-between align-items-center border-bottom mb-3 pb-3'>
                <h3
                  style={{
                    fontSize: '20px',
                    fontWeight: '600',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  {title}
                </h3>
                <Close
                  className='cursor-pointer'
                  onClick={() => {
                    onClose()
                    resetForm()
                  }}
                />
              </div>
              <div className='mb-2 row-gap-1 text-center'>
                <div className='mb-3 mt-1'>{subTitle}</div>
                <div className='mb-3 mt-1'>
                  <OutlinedInput
                    fullWidth
                    error={Boolean(touched.password && errors.password)}
                    id='-password-login'
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    name='password'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className='modal_input'
                    sx={{
                      // border: `1px solid #d9d9d900`,
                      borderRadius: '6px 0px 0px 6px',
                      borderRight: 'none',
                      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${getColorCode('redToBlue')} !important`
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${getColorCode('redToBlue')} !important`
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${getColorCode('redToBlue')} !important`
                      }
                    }}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPassword}
                          edge='end'
                          sx={{ color: getColorCode('infoBlue') }}
                        >
                          {showPassword ? <Eye /> : <EyeSlash />}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder='Enter New password'
                    inputProps={{ 'data-testid': `${testId}_input` }}
                  />
                  {touched.password && errors.password && (
                    <span
                      className='errorText'
                      style={{ color: getColorCode('redToBlue'), display: 'block', textAlign: 'left' }}
                    >
                      {errors.password}
                    </span>
                  )}
                  <div className='mt-3'>
                    <Button
                      type='submit'
                      variant='contained'
                      size='medium'
                      sx={{
                        background: getColorCode('infoBlue'),
                        marginRight: '10px',
                        '&:hover': {
                          backgroundColor: getColorCode('infoBlue')
                        }
                      }}
                      data-testid={`${testId}_set`}
                    >
                      Set
                    </Button>

                    {/* <Button
                      onClick={() => {
                        resetForm()
                        onClose()
                      }}
                      size='medium'
                      variant='outlined'
                      sx={{
                        color: getColorCode('infoBlue'),
                        borderColor: getColorCode('infoBlue'),
                        textTransform: 'capitalize'
                      }}
                    >
                      Cancel
                    </Button> */}
                  </div>
                </div>

                <div></div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  )
}

export default ResetWifiPassword
