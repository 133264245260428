import React from 'react'

const Satellites = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.4688 11.0766H11.8203L9.03125 8.2875V5.92344H10.9438C11.2359 5.92344 11.475 5.68437 11.475 5.39219V0.53125C11.475 0.239062 11.2359 0 10.9438 0H6.05625C5.76406 0 5.525 0.239062 5.525 0.53125V5.39219C5.525 5.68437 5.76406 5.92344 6.05625 5.92344H7.96875V8.2875L5.17969 11.0766H0.53125C0.239062 11.0766 0 11.3156 0 11.6078V16.4688C0 16.7609 0.239062 17 0.53125 17H5.39219C5.68437 17 5.92344 16.7609 5.92344 16.4688V11.8203L8.5 9.24375L11.0766 11.8203V16.4688C11.0766 16.7609 11.3156 17 11.6078 17H16.4688C16.7609 17 17 16.7609 17 16.4688V11.6078C17 11.3156 16.7609 11.0766 16.4688 11.0766ZM6.5875 1.0625H10.3859V4.86094H6.5875V1.0625ZM4.86094 15.9375H1.0625V12.1391H4.86094V15.9375ZM15.9375 15.9375H12.1391V12.1391H15.9375V15.9375Z" fill="black"/>
    </svg>

  )
}
export default Satellites
