import React from 'react'

const ConfigurationImage = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.0833 17.2411H18.1054C17.7838 16.1709 16.8003 15.3843 15.6268 15.3843C14.4533 15.3843 13.4706 16.1709 13.1483 17.2411H3.74274C3.33274 17.2411 3 17.5739 3 17.9839C3 18.3939 3.33274 18.7267 3.74274 18.7267H13.1482C13.4698 19.797 14.4533 20.5835 15.6268 20.5835C16.8003 20.5835 17.783 19.797 18.1053 18.7267H20.0833C20.494 18.7267 20.826 18.3939 20.826 17.9839C20.826 17.5739 20.4941 17.2411 20.0833 17.2411ZM15.6268 19.0981C15.0125 19.0981 14.5127 18.5982 14.5127 17.9839C14.5127 17.3697 15.0125 16.8698 15.6268 16.8698C16.2411 16.8698 16.7409 17.3697 16.7409 17.9839C16.7409 18.5982 16.2411 19.0981 15.6268 19.0981Z" fill="#636363"/>
      <path d="M20.0833 5.35687H18.1054C17.783 4.28658 16.8003 3.5 15.6268 3.5C14.4533 3.5 13.4706 4.28658 13.1482 5.35687H3.74274C3.33274 5.35687 3 5.68961 3 6.09961C3 6.50961 3.33274 6.84236 3.74274 6.84236H13.1482C13.4706 7.91269 14.4533 8.69926 15.6268 8.69926C16.8004 8.69926 17.783 7.91269 18.1054 6.84239H20.0833C20.4941 6.84239 20.8261 6.50965 20.8261 6.09965C20.8261 5.68965 20.4941 5.35687 20.0833 5.35687ZM15.6268 7.21374C15.0126 7.21374 14.5127 6.71388 14.5127 6.09961C14.5127 5.48535 15.0126 4.98548 15.6268 4.98548C16.2411 4.98548 16.7409 5.48535 16.7409 6.09961C16.7409 6.71388 16.2411 7.21374 15.6268 7.21374Z" fill="#636363"/>
      <path d="M20.0833 11.2988H10.6779C10.3555 10.2285 9.37282 9.44189 8.1993 9.44189C7.02577 9.44189 6.04307 10.2285 5.72074 11.2988H3.74274C3.33274 11.2988 3 11.6315 3 12.0415C3 12.4515 3.33274 12.7843 3.74274 12.7843H5.72071C6.04307 13.8545 7.02573 14.6411 8.19926 14.6411C9.37279 14.6411 10.3555 13.8545 10.6778 12.7843H20.0833C20.494 12.7843 20.826 12.4515 20.826 12.0415C20.8261 11.6315 20.4941 11.2988 20.0833 11.2988ZM8.19926 13.1556C7.58499 13.1556 7.08513 12.6558 7.08513 12.0415C7.08513 11.4272 7.58499 10.9274 8.19926 10.9274C8.81353 10.9274 9.31339 11.4272 9.31339 12.0415C9.31339 12.6558 8.81353 13.1556 8.19926 13.1556Z" fill="#636363"/>
    </svg>
  )
}
export default ConfigurationImage
