import { useQuery, useQueryClient } from '@tanstack/react-query'
import xcpemSec from '../axiosInstances/xcpemSec'
import { notifyError } from '../components/Toaster'
import { useState } from 'react'

export const useEnvironmentHealth = ({
  dev_mesh_role,
  parent_gw_uid,
  wan_mac,
  gw_uid
}: any): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const gwuid = gw_uid || localStorage.getItem('gwuid')
  const [data, setData] = useState<any | undefined>(undefined)
  const { isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['envHealth', gwuid],
    async () => {
      const endpoint =
        dev_mesh_role === 'CAP'
          ? `/rpc/webapp_wifi_env_health?_gw_uid=${gwuid}&_mesh_role=${dev_mesh_role}`
          : `/rpc/webapp_wifi_env_health?_gw_uid=${parent_gw_uid}&_mesh_role=${dev_mesh_role}&_mac_satellite=${wan_mac}`
      if (!!dev_mesh_role) {
        try {
          const response = await xcpemSec.get<any[]>(endpoint)

          return response.data
        } catch (error: any) {
          if (error?.response?.status === 401) {
            return error?.response?.status
          } else {
            notifyError(`Wifi env health: ${error?.response?.data?.message || error?.message}`)
          }

          
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    // Manually clear the previous data
    setData(undefined)

    // Invalidate the query cache
    await queryClient.invalidateQueries(['envHealth'] as readonly unknown[])

    // Trigger the refetch
    refetch()
  }
  return { data, isLoading, error, isFetching, isFetched, refetch: handleRefetch }
}
