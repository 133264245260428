import { useQuery, useQueryClient } from '@tanstack/react-query'
import xcpemSec from '../axiosInstances/xcpemSec'
import { GatewayStats } from '../models/Xcpem'
import { notifyError } from '../components/Toaster'
import { useState } from 'react'

export const useGatewayStats = (
  from: string,
  till: string,
  activeGw_Uid?: string
): {
  data: GatewayStats[] | undefined | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const [data, setData] = useState<any | undefined>(undefined)

  const { isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['gatewaylogs'],
    async () => {
      const gwuid = !!activeGw_Uid ? activeGw_Uid : localStorage.getItem('gwuid')

      if (!gwuid) return undefined

      const endpoint = `/rpc/webapp_gw_stats_history?_gw_uid=${gwuid}&_timestamp_from=${from}&_timestamp_till=${till}`
      try {
        const response = await xcpemSec.get<GatewayStats[] | any>(endpoint)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError('Failed to fetch gateway statistics')

          throw new Error('Failed to fetch gateway statistics')
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
      retry: 1,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData(undefined)
    await queryClient.invalidateQueries(['gatewaylogs'] as readonly unknown[])
    refetch()
  }

  return { data, isLoading, error, isFetching, isFetched, refetch: handleRefetch }
}
export const useGatewayStatsChart = (
  from: string,
  till: string,
  activeGw_Uid?: string
): {
  data: GatewayStats[] | undefined | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const [data, setData] = useState<any | undefined>(undefined)
  const { isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['gatewaylogsChart'],
    async () => {
      const gwuid = !!activeGw_Uid ? activeGw_Uid : localStorage.getItem('gwuid')

      if (!gwuid) return undefined

      const endpoint = `/rpc/webapp_gw_stats_history?_gw_uid=${gwuid}&_timestamp_from=${from}&_timestamp_till=${till}`
      try {
        const response = await xcpemSec.get<GatewayStats[] | any>(endpoint)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Stats history chart: ${error?.response?.data?.message || error?.message}`)
        }
       
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
      retry: 1,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData(undefined)
    await queryClient.invalidateQueries(['gatewaylogsChart'] as readonly unknown[])
    refetch()
  }
  return { data, isLoading, error, isFetching, isFetched, refetch: handleRefetch }
}
