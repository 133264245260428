import React from 'react'
import SystemOverview from './systemOverview'
import CustomertNotes from './custometNotes'
import { Tooltip } from 'antd'

const SystemOverviewDetails = ({ gatewayInfo, gatewayFeaching }: any) => {
  return (
    <div className='me-3 middle_parts'>
      <div className='w-full '>
        <div className='w-full'>
          {/*<Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: '#707e8c'
                          
                        }
                      }
                    }} placement='top' title='Coming Soon'> */}
          <div className='system-overview-cls'>
            <label className='system-overview-label-cls'>System Overview</label>
            <SystemOverview
              gatewayInfo={gatewayInfo}
              gatewayFeaching={gatewayFeaching}
              // gatewayRefetch={gatewayRefetch}
            />
          </div>
          {/* </Tooltip> */}
        </div>
        <div className='w-full '>
          <div className='notes-cls mt-3'>
            <label className='notes-label-cls'>Notes</label>
            <CustomertNotes />
          </div>
        </div>
        <div className='w-full '>
          {/* Tags Section */}
          {/* <div className='tags-cls mt-3'>
            <label className='tags-label-cls'>Tags</label>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default SystemOverviewDetails
