import React from 'react'

const LogsIcon = () => {
  return (
    <div>
      <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clip-path='url(#clip0_2264_486)'>
          <path
            d='M8.94441 7.55559H15.0556V11.4444H8.94441V7.55559ZM16.1667 7.55559H22V11.4444H16.1667V7.55559ZM8.94441 17.5556H15.0556V22H8.94441V17.5556ZM2 7.55559H7.83328V11.4444H2V7.55559ZM8.94441 12.5556H15.0556V16.4444H8.94441V12.5556ZM22 6.44441V4.77773C21.9986 3.24422 20.7558 2.00137 19.2223 2H4.77773C3.24418 2.00137 2.00133 3.24422 2 4.77773V6.44441H22ZM7.83328 17.5556H2V19.2223C2.00137 20.7558 3.24422 21.9986 4.77773 22H7.83328V17.5556ZM2 12.5556H7.83328V16.4444H2V12.5556ZM16.1667 17.5556V22H19.2223C20.7558 21.9986 21.9986 20.7558 22 19.2223V17.5556H16.1667ZM16.1667 12.5556H22V16.4444H16.1667V12.5556Z'
            fill='#636363'
          />
        </g>
        <defs>
          <clipPath id='clip0_2264_486'>
            <rect width='20' height='20' fill='white' transform='translate(2 2)' />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default LogsIcon
