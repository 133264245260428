import { useEffect, useRef } from 'react'

type RefObject<T> = {
  current: T | null
}

type ClickOutsideCallback = (event: MouseEvent) => void

function useClickOutside(ref: RefObject<HTMLElement | null>, callback: ClickOutsideCallback) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback(event)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, callback])
}

export default useClickOutside
