import { DashboardCountState, DashboardCountAction } from './types';

const initialState: DashboardCountState = {
    data: [],
    loading: false,
    error: null,
};

const dashboardProblemsReducer = (state = initialState, action: DashboardCountAction): DashboardCountState => {
    switch (action.type) {
        case 'GET_DASHBOARD_COUNT_REQUEST':
            return {
                ...state,
                loading: true,
                error: null,
            };
        case 'GET_DASHBOARD_COUNT_SUCCESS':
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null,
            };
        case 'GET_DASHBOARD_COUNT_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default dashboardProblemsReducer;
