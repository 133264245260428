import axios from 'axios'
import { KEYCLOAK_CONFIG, apiUrl } from '../app-config'

const xcpemSec = axios.create({
  baseURL: apiUrl
})

let isRefreshing = false
let refreshSubscribers: any = []

const refreshToken = async () => {
  try {
    const token = localStorage.getItem('refresh_token')
    if (!token) {
      throw new Error('Refresh token not found')
    }

    const body = new URLSearchParams()
    body.append('client_id', KEYCLOAK_CONFIG.CLIENT_ID)
    body.append('client_secret', KEYCLOAK_CONFIG.CLIENT_SECRET)
    body.append('grant_type', 'refresh_token')
    body.append('refresh_token', token)

    const response = await axios.post(
      `${KEYCLOAK_CONFIG.URL}/auth/realms/${KEYCLOAK_CONFIG.REALM}/protocol/openid-connect/token`,
      body
    )
    const newAccessToken = response.data.access_token
    const newRefreshToken = response.data.refresh_token

    localStorage.setItem('access_token', newAccessToken)
    localStorage.setItem('refresh_token', newRefreshToken)
    localStorage.setItem('refresh_token_api', 'store')

    isRefreshing = false
    refreshSubscribers.forEach((cb: any) => cb(newAccessToken))
    refreshSubscribers = []

    return newAccessToken
  } catch (error) {
    localStorage.clear()
    window.location.href = '/#/login'
    throw error
  }
}

xcpemSec.interceptors.request.use(
  config => {
    const accessToken = localStorage.getItem('access_token')
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

xcpemSec.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    const originalRequest = error.config

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true
        try {
          const newAccessToken = await refreshToken()
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`

          return xcpemSec(originalRequest)
        } catch (error) {
          return Promise.reject(error)
        } finally {
          isRefreshing = false
        }
      } else {
        return new Promise((resolve, reject) => {
          refreshSubscribers.push((token: any) => {
            originalRequest.headers.Authorization = `Bearer ${token}`
            resolve(xcpemSec(originalRequest))
          })
        })
      }
    }

    return Promise.reject(error)
  }
)

export default xcpemSec
