import { useQuery, useQueryClient } from '@tanstack/react-query'
import { apiUrl } from '../app-config'
import xcpemSec from '../axiosInstances/xcpemSec'
import { AccountGateways } from '../models/Xcpem'
import { notifyError } from '../components/Toaster'
import { useState } from 'react'

export const useSetelliteAccount = (
  email: any,
  role: any
): {
  data: AccountGateways[] | undefined
  error: any | null
  refetch: any
  isFetching: boolean
} => {
  const [data, setData] = useState<any | undefined>([])
  const { error, refetch, isFetching } = useQuery(
    ['account-setellite-gateways'],
    async () => {
      if (!email) return data

      const endpoint = `${apiUrl}/webapp_dashboard_all_devices?email=ilike.*${encodeURIComponent(
        email
      )}*&dev_mesh_role=eq.NonCAP`
      // )}*&dev_mesh_role=eq.${role || 'NonCAP'}`
      try {
        const response = await xcpemSec.get<AccountGateways[]>(endpoint)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`All devices: ${error?.response?.data?.message || error?.message}`)
        }

      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 1,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    // Manually clear the previous data
    setData(undefined)

    // Invalidate the query cache
    await queryClient.invalidateQueries(['account-setellite-gateways'] as readonly unknown[])

    // Trigger the refetch
    refetch()
  }
  return { data, error, refetch: handleRefetch, isFetching }
}
