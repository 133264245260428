import React from 'react'

const DeviceControlImage = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 9.49999C10.2857 9.49999 8.89597 10.8431 8.89597 12.5C8.89597 14.1568 10.2857 15.5 12 15.5C13.7143 15.5 15.104 14.1568 15.104 12.5C15.104 10.8431 13.7143 9.49999 12 9.49999ZM20.7124 10.3592L19.872 11.0279C18.9133 11.7906 18.9133 13.2094 19.872 13.9721L20.7124 14.6408C21.0102 14.8778 21.0871 15.2881 20.8942 15.611L19.2348 18.389C19.0418 18.7119 18.6357 18.8527 18.2745 18.7219L17.2551 18.3528C16.0923 17.9318 14.821 18.6411 14.6168 19.8249L14.4379 20.8627C14.3745 21.2304 14.0453 21.5 13.6595 21.5H10.3405C9.95474 21.5 9.6255 21.2304 9.5621 20.8627L9.38312 19.8249C9.179 18.6411 7.90771 17.9318 6.74492 18.3528L5.72557 18.7219C5.36429 18.8527 4.95815 18.7119 4.76524 18.389L3.10576 15.611C2.91289 15.2881 2.9898 14.8778 3.28763 14.6408L4.12808 13.9721C5.08671 13.2094 5.08671 11.7906 4.12808 11.0279L3.28763 10.3592C2.9898 10.1222 2.91289 9.71187 3.10576 9.38897L4.76524 6.61099C4.95815 6.2881 5.36429 6.1473 5.72557 6.2781L6.74492 6.64718C7.90771 7.06819 9.179 6.35884 9.38312 5.17507L9.5621 4.13728C9.6255 3.76954 9.95474 3.5 10.3405 3.5H13.6595C14.0453 3.5 14.3745 3.76954 14.4379 4.13728L14.6168 5.17507C14.821 6.35884 16.0923 7.06819 17.2551 6.64718L18.2745 6.2781C18.6357 6.1473 19.0418 6.2881 19.2348 6.61099L20.8942 9.38897C21.0871 9.71187 21.0102 10.1222 20.7124 10.3592Z" stroke="#636363" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
export default DeviceControlImage
