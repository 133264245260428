import React, { ReactNode } from 'react'
import { useOnlineStatus } from '../../hook/useOnlineStatus'
import NoInternet from '../../components/NoInternet'

type CommonLayoutProps = {
  children: ReactNode
}

const CommonLayout = ({ children }: CommonLayoutProps) => {
  const isOnline = useOnlineStatus()

  return (
    <>
      {!isOnline && <NoInternet />}
      <div className='common-layout-wrapper'>{children}</div>
      {/* <ToastContainer
        position='bottom-right'
        // autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      /> */}
    </>
  )
}

export default CommonLayout
