import React, { useEffect, useState } from 'react'
import QualityOfExperience from './charts/quality-of-experience'
import SignalQuality from './charts/signal-quality'
import SignalLevel from './charts/signal-level'
import OverallThroughput from './charts/overall-throughput'
import RxFailureRate from './charts/rx-failure-rate'
import TxFailureRate from './charts/tx-failure-rate'
import ChannelUtilizationChart from './charts/channel-utilization'
import EffectivePhyRxRate from './charts/effective-rate-rx'
import EffectivePhyTxRate from './charts/effective-rate-tx'
import Active from './charts/active'
import PredictedThroughput from './charts/predicted-throughput'
import ThreatsTile from './threats-tile'
import { useDispatch, useSelector } from 'react-redux'
import { NetworkHealthMapRootState } from '../../../redux/networkHealthMap/types'
import { getColorCode } from '../../../utils/helper'
import { getNetworkDevices, getNetworkMapDevices } from '../../../redux/networkHealthMap/networkHealthMap'
import moment from 'moment'
import { CustomersRootState } from '../../../redux/customerStatistics/types'
import { ThunkDispatch } from 'redux-thunk'
import { LinearProgress } from '@mui/material'
import { NetworkMapDevice } from '../../../models/CustomerTable'
import DataConsumption from './charts/data-consumption'
import { useQoeChart } from '../../../hook/useQoeChart'
import Loader from '../../../components/Loader'
import SessionTimeOutModal from '../../../components/SessionTimeOutModal'
import { useSessionContex } from '../../../SessionContex'
import { useGatewayInfo } from '../../../hook/useGatewayInfo'

const QoePage = () => {
  const dispatch: any = useDispatch()
  const dispatchNetworkHealthMap: ThunkDispatch<NetworkHealthMapRootState, unknown, any> = useDispatch()
  const [selectedMacData, setSelectedMacData] = useState<any>('')
  const [activeTile, setActiveTile] = useState('') // active mac id
  const [randomNumber, setRandomNumber] = useState(0)
  const [randomConnectionNumber, setRandomConnectionNumber] = useState('-')
  const [feedback, setFeedback] = useState('')
  const [feedbackColor, setFeedbackColor] = useState('')
  const [pageLoading, setPageLoading] = useState(true)
  // const [randomNumberRssi, setRandomNumberRssi] = useState(0)
  const [myData, setMyData] = useState<any>([])
  const networkMapDevices: NetworkMapDevice[] = useSelector(
    (state: NetworkHealthMapRootState) => state.networkHealthMap.data
  )
  const networkMapDevicesError: any = useSelector((state: NetworkHealthMapRootState) => state.networkHealthMap.error)
  const networkMapLoading: boolean = useSelector((state: NetworkHealthMapRootState) => state.networkHealthMap.loading)
  const activeGw_Uid: string = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)
  const masterDevice: any = networkMapDevices?.filter((item: NetworkMapDevice) => item.mesh_role === 'master')[0]
  const firstLayerDevices = networkMapDevices?.filter((item: NetworkMapDevice) => item.parent === masterDevice?.mac)

  const formattedTimestamp = moment.utc().subtract(24, 'hours').format('YYYY-MM-DDTHH:mm:ss')
  const timestamp = moment.utc(formattedTimestamp).toISOString()
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()

  const { data, isFetching, refetch } = useQoeChart(activeGw_Uid, activeTile, timestamp)
  const { data: gatewayInfo, refetch: gatewayInfoRef }: any = useGatewayInfo(activeGw_Uid) //User device data

  // const deviceList = []
  // for (const entry of networkMapDevices) {
  //   const value = entry.mesh_role === 'master' ? entry.ssid : entry.device_name
  //   deviceList.push(value !== null ? value : '*')
  // }
  // const defaultFilter = moment().startOf('day').format(`${process.env.REACT_APP_DATE_FORMAT} HH:mm:ss`)

  useEffect(() => {
    if (!!activeGw_Uid) {
      dispatchNetworkHealthMap(getNetworkMapDevices({ gwUid: activeGw_Uid as string }))
      gatewayInfoRef()
    }
  }, [activeGw_Uid])
  useEffect(() => {
    if (reCall) {
      dispatchNetworkHealthMap(getNetworkMapDevices({ gwUid: activeGw_Uid as string }))
      gatewayInfoRef()
      // refetch()
    }
  }, [reCall])

  useEffect(() => {
    if (!networkMapLoading) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [networkMapLoading])

  useEffect(() => {
    if (!!activeTile && !!activeGw_Uid && networkMapDevices !== undefined) {
      // setPageLoading(true)
      refetch()
    } else {
      setPageLoading(false)
    }
  }, [activeTile, myData, activeGw_Uid])

  useEffect(() => {
    if (networkMapDevicesError === 401 || data === 401) {
      setPageLoading(false)
      dispatchNetworkHealthMap({
        type: 'GET_NETWORK_MAP_DEVICES_FAILURE',
        error: null
      })
      !openModal && setOpenModal(true)
    }
  }, [data, networkMapDevicesError])

  useEffect(() => {
    // const firstLayerEndDevices = networkMapDevices.filter(
    //   (item: NetworkMapDevice) =>
    //     item.parent === masterDevice?.mac &&
    //     item.mesh_role !== 'master' &&
    //     item.gw_uid === null &&
    //     item.mesh_role !== 'satellite'
    // )

    // const firstLayerSatellites = firstLayerDevices.filter(
    //   (item: NetworkMapDevice) => item.parent === masterDevice?.mac && item.mesh_role === 'satellite'
    // )

    // firstLayerEndDevices.map(obj => {
    //   return obj
    // })

    // const ab: any = firstLayerEndDevices

    // firstLayerSatellites.map((item: any) => {
    //   ab.push(item)
    //   networkMapDevices
    //     .filter(childItem => childItem.parent === item?.mac)
    //     .map((loopItem: any) => {
    //       ab.push(loopItem)
    //     })
    // })

    // ab.unshift(masterDevice)
    const ab: any = networkMapDevices
      ? networkMapDevices?.filter(
          (obj: any) =>
            obj.client_conn_type !== 'ETHERNET' && obj.mesh_role !== 'satellite' && obj.mesh_role !== 'master'
        )
      : []
    setMyData(ab)
    if (ab?.length > 0) {
      const filterData = ab?.filter((i: any) => {
        return i?.mesh_role === null
      })
      setPageLoading(true)
      setSelectedMacData(filterData?.length !== 0 ? filterData[0]?.mac : ab[0]?.mac) // set mac id as first device
      setActiveTile(filterData?.length !== 0 ? filterData[0]?.mac : ab[0]?.mac) // set mac id as first device
    }
  }, [networkMapDevices])

  useEffect(() => {
    if (!!myData?.filter((i: any) => i?.mac === activeTile)[0]?.channel) {
      myData?.filter((i: any) => i?.mac === activeTile)[0]?.channel <= 14
        ? setRandomConnectionNumber('2.4G')
        : setRandomConnectionNumber('5G')
    } else {
      setRandomConnectionNumber('-')
    }
  }, [activeTile, myData])

  useEffect(() => {
    setPageLoading(isFetching)
  }, [isFetching])

  useEffect(() => {
    setActiveTile(selectedMacData)
    // myData &&
    //   myData.length !== 0 &&
    //   selectedMacData !== '' &&
    //   dispatch(getNetworkDevices(activeGw_Uid, selectedMacData, defaultFilter))
  }, [selectedMacData])

  useEffect(() => {
    // setRandomConnectionNumber(randomNumber)

    // Determine the feedback based on the random number
    if (data?.length > 0 && myData?.length > 0 && !!myData[0]) {
      if (data[data?.length - 1].qoe_score === 3) {
        setFeedbackColor(getColorCode('Closed'))
        setFeedback('Excellent')
        setRandomNumber(100)
      } else if (data[data?.length - 1].qoe_score === 2) {
        setFeedbackColor(getColorCode('Warning'))
        setFeedback('Good')
        setRandomNumber(50)
      } else if (data[data?.length - 1].qoe_score === 1) {
        setFeedbackColor(getColorCode('Critical'))
        setRandomNumber(20)
        setFeedback('Poor')
      } else {
        setFeedbackColor('grey')
        setRandomNumber(0)
        setFeedback('')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedMacData])

  const onDeviceChange = (deviceId: any) => {
    if (selectedMacData !== deviceId) {
      setSelectedMacData(deviceId)
      setPageLoading(true)
      if (data?.length > 0 && myData.length > 0 && !!myData[0]) {
        if (data[data?.length - 1].qoe_score === 3) {
          setFeedbackColor(getColorCode('Closed'))
          setFeedback('Excellent')
          setRandomNumber(100)
        } else if (data[data?.length - 1].qoe_score === 2) {
          setFeedbackColor(getColorCode('Warning'))
          setFeedback('Good')
          setRandomNumber(50)
        } else if (data[data?.length - 1].qoe_score === 1) {
          setFeedbackColor(getColorCode('Critical'))
          setRandomNumber(20)
          setFeedback('Poor')
        } else {
          setFeedbackColor('grey')
          setRandomNumber(0)
          setFeedback('')
        }
      }
    }
  }

  const keysToIgnore = ['timestamp', 'rssi']

  // Check if any value (except those in keysToIgnore) in the object is null
  const hasNullValue =
    data?.length === 1
      ? Object.keys(data[0])
          .filter(key => !keysToIgnore.includes(key))
          .some(key => data[key] === null)
      : false

  return (
    <div className='qoe-wrapper'>
      {networkMapLoading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <>
          {!pageLoading && !isFetching && !gatewayInfo?.is_online ? (
            <div className='flex justify-center items-center pt-4' style={{ color: getColorCode('redToBlue') }}>
              Device is currently offline. To boost your Quality of Experience (QoE), please restore connectivity and
              reconnect your WiFi client device
            </div>
          ) : myData?.length === 0 ? (
            <div className='flex justify-center items-center pt-4' style={{ color: getColorCode('redToBlue') }}>
              Optimize Your Experience: QoE metrics are available for WiFi client devices only. Please connect to WiFi
              to access detailed Quality of Experience insights.
            </div>
          ) : (
            <>
              <div className='qoe-device-wrapper'>
                {myData?.length > 0 && !!myData[0] ? (
                  <ThreatsTile
                    devices={myData}
                    onChange={onDeviceChange}
                    activeTile={activeTile}
                    setActiveTile={setActiveTile}
                    displayLock={false}
                  />
                ) : (
                  <div
                    key='threat_Tile_all'
                    className={`device-wrapper justify-center align-center w-full ${
                      activeTile === '*' ? 'active-border' : 'in-active-border'
                    }`}
                  >
                    <span
                      className={`ooverflow-hidden text-center overflow-ellipsis whitespace-nowrap min-w-[110px] w-full
            ${
              activeTile === '*' ? 'text-alerts-critical font-sf-regular' : 'font-sf-regular text-layout-textOnSurface'
            }`}
                      style={{ color: getColorCode('redToBlue') }}
                    >
                      No devices found
                    </span>
                  </div>
                )}
              </div>
              <div className='qoe-chart'>
                <div className='qoe_table'>
                  <div className='d-flex justify-between'>
                    <div>
                      <div className={'deviceDetsils'}>Connection</div>
                      <div className={'deviceData'}>{`${randomConnectionNumber}`}</div>
                    </div>

                    <>
                      <div>
                        <div className={'deviceDetsils'}>QoE</div>
                        <div className={'deviceData'}>
                          <div className='flex items-center'>
                            <span style={{ color: feedbackColor }} className='inline-flex items-center gap-x-[10px]'>
                              {!!activeTile ? (
                                <>
                                  <LinearProgress
                                    variant='determinate'
                                    value={randomNumber}
                                    sx={{
                                      '& .MuiLinearProgress-bar': {
                                        backgroundColor: feedbackColor
                                      },
                                      background: '#EFF2FC',
                                      width: '60px',
                                      height: '10px',
                                      borderRadius: '6px'
                                    }}
                                  />
                                  {feedback}
                                </>
                              ) : (
                                <LinearProgress
                                  variant='determinate'
                                  value={0}
                                  sx={{
                                    '& .MuiLinearProgress-bar': {
                                      backgroundColor: feedbackColor
                                    },
                                    background: '#EFF2FC',
                                    width: '60px',
                                    height: '10px',
                                    borderRadius: '6px'
                                  }}
                                />
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className={'deviceDetsils'}>RSSI</div>
                        <div className={'deviceData'}>{`${
                          !!activeTile && data?.length > 0 && myData?.length > 0 && !!myData[0]
                            ? data[data?.length - 1].rssi || 0
                            : 0
                        }dBm`}</div>
                      </div>
                    </>
                  </div>
                </div>
                {pageLoading || isFetching ? (
                  <div>
                    <Loader />
                  </div>
                ) : (!!activeTile && myData?.length > 0 && !!myData[0] && data?.length > 0) ||
                  (!!activeTile && data?.length === 1 && !hasNullValue) ? (
                  <>
                    <div className='qoe-chart-wrapper'>
                      <QualityOfExperience data={data} />
                    </div>
                    <div className='qoe-chart-wrapper'>
                      <SignalQuality data={data} />
                    </div>
                    <div className='qoe-chart-wrapper'>
                      <SignalLevel data={data} />
                    </div>
                    <div className='qoe-chart-wrapper'>
                      <DataConsumption data={data} />
                    </div>
                    <div className='qoe-chart-wrapper'>
                      <RxFailureRate data={data} />
                    </div>
                    <div className='qoe-chart-wrapper'>
                      <TxFailureRate data={data} />
                    </div>
                    <div className='qoe-chart-wrapper'>
                      <ChannelUtilizationChart data={data} />
                    </div>
                    <div className='qoe-chart-wrapper'>
                      <EffectivePhyRxRate data={data} />
                    </div>
                    <div className='qoe-chart-wrapper'>
                      <EffectivePhyTxRate data={data} />
                    </div>
                    <div className='qoe-chart-wrapper'>
                      <Active data={data} />
                    </div>
                    <div className='qoe-chart-wrapper'>
                      <PredictedThroughput data={data} />
                    </div>
                    <div className='qoe-chart-wrapper'>
                      <OverallThroughput data={data} />
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      height: 'calc(100vh - 350px)',
                      background: '#ffffff',
                      borderRadius: '10px',
                      color: getColorCode('redToBlue')
                    }}
                    className='d-flex justify-center align-center'
                  >
                    <span>No data found</span>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default QoePage
