import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './styles.scss'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BreadcrumbProvider } from './contexts/BreadcrumbContext'
import AuthProvider from './contexts/authContext'
import theme from './utils/Teame'
import { ThemeProvider } from '@mui/material'
import { SessionContexProvider } from './SessionContex'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const queryClient = new QueryClient()
root.render(
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      {/* <React.StrictMode> */}
      <SessionContexProvider>
        <BreadcrumbProvider>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </BreadcrumbProvider>
      </SessionContexProvider>
      {/* </React.StrictMode> */}
    </AuthProvider>
  </QueryClientProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
