import React from "react";

interface WiFiCoverage1Props {
  width?: string;
  height?: string;
}

const WiFiCoverage1: React.FC<WiFiCoverage1Props> = ({ width, height}) => {
  return (
    <svg width={width ? width : '20px'} height={height ? height : '15px'} viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.19924 6.83407C2.78905 7.24426 2.78905 7.91181 3.19924 8.32602C3.60943 8.73621 4.27699 8.73621 4.69119 8.32602C7.53033 5.48689 12.151 5.48689 14.9901 8.32602C15.1952 8.53112 15.4646 8.63567 15.7341 8.63567C16.0035 8.63567 16.2729 8.53112 16.478 8.32602C16.8882 7.91584 16.8882 7.24828 16.478 6.83407C12.8185 3.17054 6.85875 3.17054 3.19924 6.83407Z" fill="black"/>
      <path d="M6.04641 9.68114C5.63622 10.0913 5.63622 10.7589 6.04641 11.1731C6.4566 11.5833 7.12415 11.5833 7.53836 11.1731C8.80511 9.90232 10.8721 9.90232 12.1389 11.1731C12.344 11.3782 12.6134 11.4827 12.8828 11.4827C13.1523 11.4827 13.4217 11.3782 13.6268 11.1731C14.037 10.7629 14.037 10.0953 13.6268 9.68516C11.5397 7.58999 8.13755 7.58999 6.04641 9.68114Z" fill="black"/>
      <path d="M9.83854 14.8729C10.6137 14.8729 11.242 14.2445 11.242 13.4694C11.242 12.6943 10.6137 12.0659 9.83854 12.0659C9.06342 12.0659 8.43506 12.6943 8.43506 13.4694C8.43506 14.2445 9.06342 14.8729 9.83854 14.8729Z" fill="black"/>
      <path d="M19.3692 3.93899C14.1132 -1.313 5.56365 -1.313 0.30764 3.93899C-0.102547 4.34918 -0.102547 5.01674 0.30764 5.43095C0.717826 5.84113 1.38538 5.84113 1.79959 5.43095C6.23121 0.995304 13.4457 0.995304 17.8813 5.43095C18.0864 5.63604 18.3558 5.7406 18.6253 5.7406C18.8947 5.7406 19.1641 5.63604 19.3692 5.43095C19.7794 5.02076 19.7794 4.3532 19.3692 3.93899Z" fill="black"/>
    </svg>

  );
};

export default WiFiCoverage1;
